import { coinConvert } from "@stakeordie/griptape.js";
import { Dispatch, useContext, useEffect, useState } from "react";
import { KeplrToolsContext } from "../context/KeplrToolsContext";
import globalStores from "../stores/globalStores";
import { TxHandler } from "../utils/interfaces";
import Loading from "./Loading";

const MAX_SLIPPAGE = 5;

export default ({
    sellTicketsOrderBookModal,
    setSellTicketsOrderBookModal
}: {
    sellTicketsOrderBookModal: boolean,
    setSellTicketsOrderBookModal: Dispatch<boolean>
}) => {
    const keplrTools = useContext(KeplrToolsContext)
    const { auctionSelected, feedAuctionsInfo } = globalStores.auctionStore;
    const { myNFTs, getMyNFTs } = globalStores.myNFTsStore;
    const { getSefiBalance } = globalStores.walletInfoStore
    const { getBuyOrderBookState, buyOrderBookState, buyOrderBookStateLoading, executeSellOrderLoading, executeSellOrder, getUserOrdersState, userOrdersStateLoading } = globalStores.orderbookStore;

    const [txResponse, setTxResponse] = useState<TxHandler | null>(null);
    const [myActiveTickets, setMyActiveTickets] = useState<string[]>([]);
    const [ticketCount, setTicketCount] = useState<string>("");

    useEffect(() => {
        setTicketCount("")
        setMyActiveTickets([])
        setTxResponse(null)
        if (keplrTools && auctionSelected && myNFTs[auctionSelected[0]] && feedAuctionsInfo && sellTicketsOrderBookModal) {
            setMyActiveTickets(myNFTs[auctionSelected[0]]!.filter((myNFT) => feedAuctionsInfo[auctionSelected[0]].in_play_tokens.includes(myNFT)))
            getBuyOrderBookState(keplrTools.client)
        }
    }, [sellTicketsOrderBookModal])

    const totalSEFI = () => {
        if (!feedAuctionsInfo || ticketCount === "" || !auctionSelected || !buyOrderBookState || buyOrderBookState.orders.length === 0) {
            return ({
                totalAmount: BigInt(0),
                ordersCount: 0
            })
        }
        else {
            let count = parseInt(ticketCount);
            let totalAmount = BigInt(0);
            let ordersCount = 0;
            for (let order of buyOrderBookState.orders) {
                ordersCount++;
                if (count <= order.buy_nft_amount) {
                    totalAmount = totalAmount + (BigInt(order.price) * BigInt(count))
                    break;
                } else {
                    totalAmount = totalAmount + (BigInt(order.price) * BigInt(order.buy_nft_amount))
                    count = count - order.buy_nft_amount
                }
            }
            return ({
                totalAmount,
                ordersCount
            })
        }
    }

    if (!feedAuctionsInfo || !keplrTools || !auctionSelected) return null
    if (sellTicketsOrderBookModal)
        return (
            <div className="fixed z-10 inset-0 overflow-y-auto">
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity">
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                        <div className="border-color-3 inline-block align-bottom bg-color-2 rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full py-6 px-6">
                            {
                                txResponse === null &&
                                <>
                                    <div className="flex flex-row w-full">
                                        <div className="flex flex-col font-circular-std-black text-color-13 text-2xl">
                                            Sell Tickets
                                        </div>
                                        <div className="flex flex-col justify-center items-center bg-color-3 text-color-13  ml-auto w-8 h-8 cursor-pointer rounded-3xl p-4 text-base" onClick={() => setSellTicketsOrderBookModal(false)}>
                                            X
                                        </div>
                                    </div>
                                    {
                                        buyOrderBookStateLoading && <Loading variantBlack={false} />
                                    }
                                    {
                                        !buyOrderBookStateLoading && buyOrderBookState &&
                                        (buyOrderBookState.orders.length > 0 ?
                                            <>
                                                <div className="flex flex-row w-full pt-8 pb-4">
                                                    <div className="flex flex-col font-circular-std-book text-color-13 text-base justify-center">
                                                        How many tickets would you like to sell?
                                                    </div>
                                                    <div className="flex flex-col justify-center items-center ml-auto">
                                                        <input
                                                            value={ticketCount}
                                                            className={
                                                                `
                                        ${ticketCount === "" ? "text-color-4" : "text-color-13"}
                                         w-32
                                         p-4 bg-color-3 rounded-lg font-circular-std-book text-4xl text-center`
                                                            }
                                                            placeholder="00"
                                                            onChange={(e) => {
                                                                const reg = new RegExp('^[0-9]+$');
                                                                let value = e.target.value
                                                                let max = Math.min(buyOrderBookState.orders.reduce(function (a, b) {
                                                                    return a + b.buy_nft_amount;
                                                                }, 0), myActiveTickets.length)
                                                                if (!reg.test(value)) value = ""
                                                                if (parseInt(value) > max) value = max + ""
                                                                if (parseInt(value) > 99) value = "99"
                                                                setTicketCount(value)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="border-1 border-color-4 my-1" />
                                                <div className="flex flex-row w-full py-4">
                                                    <div className="flex flex-col justify-center text-color-8 font-circular-std-bold text-xs text-upper mr-4">
                                                        Expected Sale Total
                                                    </div>
                                                    <div className="flex flex-col justify-center font-circular-std-bold text-xl text-color-13">
                                                        {
                                                            ticketCount !== "" ?
                                                                (coinConvert("" + totalSEFI().totalAmount, 6, 'human', 0) + " SEFI") :
                                                                "0 SEFI"
                                                        }
                                                    </div>
                                                </div>
                                                <div className="border-1 border-color-4 my-1" />
                                                <div className="flex flex-row w-full py-4">
                                                    <div className="flex flex-col justify-center text-color-8 font-circular-std-bold text-xs text-upper mr-4">
                                                        Fees
                                                    </div>
                                                    <div className="flex flex-col justify-center font-circular-std-bold text-xl text-color-13 ml-auto">
                                                        2.50%
                                                    </div>
                                                </div>
                                                <div className="border-1 border-color-4 my-1" />
                                                <div className="flex flex-row w-full py-4">
                                                    <div className="flex flex-col justify-center text-color-8 font-circular-std-bold text-xs text-upper mr-4">
                                                        Expected Total Cash Out
                                                    </div>
                                                    <div className="flex flex-col justify-center font-circular-std-bold text-xl text-color-13 ml-auto">
                                                        {
                                                            ticketCount !== "" ?
                                                                (coinConvert("" + (totalSEFI().totalAmount * BigInt(975)) / BigInt(1000), 6, 'human', 0) + " SEFI") :
                                                                "0 SEFI"
                                                        }
                                                    </div>
                                                </div>
                                                <div className="flex flex-row w-full py-4">
                                                    <div className="flex flex-col justify-center text-color-8 font-circular-std-bold text-xs text-upper mr-4">
                                                        Minimum Total Cash Out
                                                    </div>
                                                    <div className="flex flex-col justify-center font-circular-std-bold text-xl text-color-13 ml-auto">
                                                        {
                                                            ticketCount !== "" ?
                                                                (coinConvert("" + (((totalSEFI().totalAmount) * BigInt(100 - MAX_SLIPPAGE)) / BigInt(100)), 6, 'human', 0) + " SEFI")
                                                                :
                                                                "0 SEFI"
                                                        }
                                                    </div>
                                                </div>
                                                <div className="border-1 border-color-4 my-1" />
                                                <div className="flex flex-row w-full mt-5">
                                                    <button
                                                        className={
                                                            `
                                                            ${!executeSellOrderLoading && " disabled:opacity-50 hover:bg-color-13 "}
                                                            w-full bg-color-11 rounded-3xl shadow-2xl p-4 font-circular-std-bold text-color-1 text-sm 
                                                            `
                                                        }
                                                        disabled={ticketCount === "" || executeSellOrderLoading || totalSEFI().totalAmount === BigInt(0)}
                                                        onClick={async () => {
                                                            const res = await executeSellOrder(
                                                                keplrTools.client,
                                                                myActiveTickets.slice(0, parseInt(ticketCount)),
                                                                "" + ((totalSEFI().totalAmount) * BigInt(100 - MAX_SLIPPAGE)) / BigInt(100),
                                                                totalSEFI().ordersCount
                                                            )
                                                            if (res.ok) {
                                                                await getUserOrdersState(keplrTools.client, keplrTools.accountAddress, auctionSelected[0])
                                                                await getSefiBalance(keplrTools.client, keplrTools.accountAddress);
                                                            }
                                                            setTxResponse(res)
                                                            //setSellTicketsOrderBookModal(false)
                                                            //const res = await mintNFTs(keplrTools.client, parseInt(ticketCount), "" + totalSEFI(), keplrTools.accountAddress)
                                                        }}>
                                                        {
                                                            (userOrdersStateLoading || executeSellOrderLoading) ?
                                                                <Loading variantBlack={true} />
                                                                :
                                                                (ticketCount !== "") ?
                                                                    "Sell " + ticketCount + " tickets" :
                                                                    "-"
                                                        }
                                                    </button>
                                                </div>
                                            </> :
                                            <div className="font-circular-std-book text-color-13 text-base my-4 mt-8">
                                                No available Buy Orders right now, please check again later...
                                            </div>
                                        )
                                    }
                                </>
                            }
                            {
                                txResponse && txResponse.ok === true &&
                                <>
                                    <div className="flex flex-row w-full justify-center items-center text-color-13 mb-2 mt-8">
                                        <i className="far fa-check-circle fa-5x "></i>
                                    </div>
                                    <div className="flex flex-row w-full justify-center items-center  text-color-13 my-2 font-circular-std-bold text-2xl text-center">
                                        {"You execute a sell order successfully!"}
                                    </div>
                                    <div className="flex flex-row w-full justify-center items-center mt-12">
                                        <button
                                            className="hover:bg-color-13 w-full bg-color-11 rounded-3xl shadow-2xl p-4 font-circular-std-bold text-color-1 text-sm"
                                            //@ts-ignore
                                            style={{ textAlign: "-webkit-center", marginBottom: "15px", width: "100%", height: "50px", background: "#E8E9EA", boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.25)", borderRadius: "24px" }}
                                            onClick={async () => {
                                                await getMyNFTs(keplrTools.client, keplrTools.accountAddress, feedAuctionsInfo[auctionSelected[0]].configs.nfts_contract.contract!.address, auctionSelected[0])
                                                setSellTicketsOrderBookModal(false)
                                            }}
                                        >
                                            Got It
                                        </button>
                                    </div>
                                </>
                            }
                            {
                                txResponse && txResponse.ok === false &&
                                <>
                                    <div className="flex flex-row w-full justify-center items-center text-color-13 mb-2 mt-8">
                                        <i className="far fa-times-circle fa-5x "></i>
                                    </div>
                                    <div className="flex flex-row w-full justify-center items-center  text-color-13 my-2 font-circular-std-bold text-2xl">
                                        Oh No, Something Went Wrong
                                    </div>
                                    <div className="flex flex-row w-full justify-center items-center  text-color-8 my-2 font-circular-std-bold text-base break-all">
                                        {
                                            txResponse.error
                                        }
                                    </div>
                                    <div className="flex flex-row w-full justify-center items-center  mt-12">
                                        <button
                                            className="hover:bg-color-13 w-full bg-color-11 rounded-3xl shadow-2xl p-4 font-circular-std-bold text-color-1 text-sm"
                                            //@ts-ignore
                                            style={{ textAlign: "-webkit-center", marginBottom: "15px", width: "100%", height: "50px", background: "#E8E9EA", boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.25)", borderRadius: "24px" }}
                                            onClick={async () => {
                                                setSellTicketsOrderBookModal(false)
                                            }}
                                        >
                                            Go Back
                                        </button>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div >)
    else return null
}