export default (errorMessage: string) => {
  try {
    let initJson = errorMessage.indexOf('{');
    const reversedFinalJson = errorMessage.split('').reverse().join('').indexOf('}');
    if (initJson !== -1 && reversedFinalJson !== -1) {
      const jsonResult = JSON.parse(errorMessage.slice(initJson, errorMessage.length - reversedFinalJson));
      return jsonResult.generic_err.msg;
    } else if (errorMessage.search('out of gas in location') !== -1) {
      return 'Out of Gas: ' + errorMessage.slice(errorMessage.search('gasWanted:'), errorMessage.search(': out of gas. Decryption'));
    } else if (errorMessage.search('You are not authorized to perform this action on token') !== -1) {
      return 'Error during the ticket transfer, please refresh the page and try again.';
    } else if (errorMessage.search('(HTTP 500)') !== -1) {
      return 'Transaction sent timed out. Please refresh the page to make sure your transaction was executed. (Error: HTTP 500)';
    } else {
      return errorMessage;
    }
  } catch (e: any) {
    return errorMessage;
  }
};
