import { coinConvert } from "@stakeordie/griptape.js";
import React, { Dispatch, useContext, useEffect, useState } from "react";
import { KeplrToolsContext } from "../context/KeplrToolsContext";
import globalStores from "../stores/globalStores";
import calcTicketsWorth from "../utils/calcTicketsWorth";
import Loading from "./Loading";

export default ({
    myNFTsModal,
    setMyNFTsModal
}: {
    myNFTsModal: boolean,
    setMyNFTsModal: Dispatch<boolean>
}) => {
    const keplrTools = useContext(KeplrToolsContext)
    const { auctionSelected, feedAuctionsInfo } = globalStores.auctionStore;
    const { myNFTs } = globalStores.myNFTsStore;
    const pageSize = 10;
    const [curPage, setCurPage] = useState<number>(1)

    const activeNFTsFirst = (myNFTs: string[]) => {
        if (!feedAuctionsInfo || !auctionSelected) return myNFTs
        let activeNFTs = myNFTs.filter((nft) => {
            if (feedAuctionsInfo[auctionSelected[0]].auction_state.mint_status) return true
            if (feedAuctionsInfo[auctionSelected[0]].in_play_tokens.includes(nft)) return true
            if (feedAuctionsInfo[auctionSelected[0]].auction_state.winner_id && feedAuctionsInfo[auctionSelected[0]].auction_state.winner_id === nft) return true
            return false
        })

        let inactiveNFTs: string[] = [];
        if (!feedAuctionsInfo[auctionSelected[0]].auction_state.mint_status) {
            inactiveNFTs = myNFTs.filter((nft) => {
                if (!feedAuctionsInfo[auctionSelected[0]].in_play_tokens.includes(nft)) return true
                return false
            })
        }

        return [...activeNFTs.sort((a, b) => parseInt(a) - parseInt(b)), ...inactiveNFTs.sort((a, b) => parseInt(a) - parseInt(b))]
    }

    if (!myNFTs || !keplrTools || !feedAuctionsInfo || !auctionSelected) return null
    if (myNFTsModal)
        return (
            <div className="fixed z-10 inset-0 overflow-y-auto">
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity">
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                        <div className="border-color-3 inline-block align-bottom bg-color-2 rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full py-6 px-6">
                            <div className="flex flex-row w-full mb-8">
                                <div className="flex font-circular-std-black text-color-13 text-2xl">
                                    Tickets Entered
                                </div>
                                <div className="flex justify-center items-center bg-color-3 text-color-13  ml-auto w-8 h-8 cursor-pointer rounded-3xl p-4 text-base" onClick={() => setMyNFTsModal(false)}>
                                    X
                                </div>
                            </div>
                            <table className="w-full">
                                <thead className="flex flex-row w-full my-4">
                                    <tr
                                        className={
                                            `${feedAuctionsInfo[auctionSelected[0]].auction_state.mint_status ? "w-4/12" : "w-3/12"}
                                        font-circular-std-bold text-color-8 text-upper text-xs`
                                        }>
                                        Ticket Number
                                    </tr>
                                    <tr className={
                                        `${feedAuctionsInfo[auctionSelected[0]].auction_state.mint_status ? "w-4/12" : "w-3/12"}
                                        font-circular-std-bold text-color-8 text-upper text-xs`
                                    }>
                                        Status
                                    </tr>
                                    <tr className={
                                        `${feedAuctionsInfo[auctionSelected[0]].auction_state.mint_status ? "w-4/12" : "w-3/12"}
                                        font-circular-std-bold text-color-8 text-upper text-xs`
                                    }>
                                        Price Paid
                                    </tr>
                                    {
                                        !feedAuctionsInfo[auctionSelected[0]].auction_state.mint_status &&
                                        <tr className={"w-3/12 font-circular-std-bold text-color-8 text-upper text-xs"}>
                                            Current Value
                                        </tr>
                                    }
                                </thead>
                                <tr className=" border-1 border-color-4 " />
                                <tbody className="w-full">
                                    {
                                        myNFTs[auctionSelected[0]] &&
                                        paginate(
                                            activeNFTsFirst(myNFTs[auctionSelected[0]]!),
                                            pageSize,
                                            curPage
                                        )
                                            .map((nft) =>
                                                <React.Fragment key={nft}>
                                                    <tr className="flex flex-row w-full text-color-13 my-2 font-circular-std-book text-sm">
                                                        <td className={
                                                            `${feedAuctionsInfo[auctionSelected[0]].auction_state.mint_status ? "w-4/12" : "w-3/12"}`
                                                        }
                                                        >{nft}</td>
                                                        <td className={
                                                            `${feedAuctionsInfo[auctionSelected[0]].auction_state.mint_status ? "w-4/12" : "w-3/12"}`
                                                        }>
                                                            {
                                                                (feedAuctionsInfo[auctionSelected[0]].auction_state.mint_status || feedAuctionsInfo[auctionSelected[0]].in_play_tokens.includes(nft)) ?
                                                                    <span><i className="fas fa-circle fa-xs mr-2" style={{ color: "#14AC3F" }}></i> Active</span>
                                                                    :
                                                                    (feedAuctionsInfo[auctionSelected[0]].auction_state.winner_id && feedAuctionsInfo[auctionSelected[0]].auction_state.winner_id === nft) ?
                                                                        <span><i className="fas fa-circle fa-xs mr-2" style={{ color: "#14AC3F" }}></i> Winner</span>
                                                                        :
                                                                        <span><i className="fas fa-circle fa-xs mr-2" style={{ color: "#C15647" }}></i> Lost </span>
                                                            }
                                                        </td>
                                                        <td className={
                                                            `${feedAuctionsInfo[auctionSelected[0]].auction_state.mint_status ? "w-4/12" : "w-3/12"}`
                                                        }>{coinConvert(feedAuctionsInfo[auctionSelected[0]].configs.nft_price, 6, 'human', 0) + " SEFI"}</td>
                                                        {
                                                            !feedAuctionsInfo[auctionSelected[0]].auction_state.mint_status &&
                                                            <td className="w-3/12">
                                                                {
                                                                    (feedAuctionsInfo[auctionSelected[0]].auction_state.mint_status || feedAuctionsInfo[auctionSelected[0]].in_play_tokens.includes(nft)) ?
                                                                        (coinConvert((parseInt(feedAuctionsInfo[auctionSelected[0]].configs.nft_price) * calcTicketsWorth(feedAuctionsInfo[auctionSelected[0]])).toString(), 6, 'human', 0)) + " SEFI" : ""
                                                                }
                                                            </td>
                                                        }
                                                    </tr>
                                                    <tr className=" border-1 border-color-4 " />
                                                </React.Fragment>
                                            )
                                    }
                                </tbody>
                            </table>
                            {
                                myNFTs[auctionSelected[0]] &&
                                <div className="flex flex-row justify-center items-center mt-8 text-color-13">
                                    <i className={
                                        `
                                        ${curPage === 1 && "opacity-50 "}
                                        fas fa-arrow-left fa-lg cursor-pointer mr-4
                                    `
                                    }
                                        onClick={() => curPage > 1 && setCurPage(curPage - 1)}
                                    >
                                    </i>
                                    <i
                                        className={
                                            `
                                        ${curPage * pageSize > myNFTs[auctionSelected[0]]!.length && "opacity-50 "}
                                        fas fa-arrow-right fa-lg cursor-pointer mr-4
                                    `}
                                        onClick={() => curPage * pageSize <= myNFTs[auctionSelected[0]]!.length && setCurPage(curPage + 1)}
                                    >
                                    </i>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    else return null
}

function paginate(array: string[], page_size: number, page_number: number) {
    // sort the array first
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice((page_number - 1) * page_size, page_number * page_size);
}