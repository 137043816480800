import { Dispatch, useContext } from "react"
import { KeplrToolsContext } from "../context/KeplrToolsContext";
import globalStores from "../stores/globalStores";

export default ({
    activeMenu,
    setActiveMenu,
    noItems
}: {
    activeMenu: string,
    setActiveMenu: Dispatch<string>,
    noItems: boolean
}) => {
    const keplrTools = useContext(KeplrToolsContext)
    const { setAuction } = globalStores.auctionStore;
    const { auctions } = globalStores.factoryStore;

    return (
        <div className="flex flex-row mb-8">
            <div className={`flex flex-row items-baseline text-color-11 text-2xl font-circular-std-bold border-b-2 border-color-3 pb-6 w-full`}>
                <img src={require("../assets/logo.png").default} style={{ width: "10rem" }}
                    onClick={() => {
                        if (keplrTools) {
                            setActiveMenu("Feed")
                            setAuction(keplrTools.client, keplrTools.accountAddress, null)
                        }
                    }} />
            </div>
            {
                keplrTools && !noItems &&
                <>
                    <div className={`${activeMenu === "Feed" ? "text-color-13" : "text-color-7"} ml-auto text-lg font-circular-std-bold px-4 border-b-2 ${activeMenu === "Feed" ? "border-color-13" : "border-color-3"} pb-6 cursor-pointer`}
                        onClick={() => {
                            setActiveMenu("Feed")
                            setAuction(keplrTools.client, keplrTools.accountAddress, null)
                        }}>
                        Feed
                    </div>
                    {
                        (auctions && auctions.length !== 0) &&
                        <div className={`${activeMenu === "Orders" ? "text-color-13" : "text-color-7"}  text-lg font-circular-std-bold px-4 border-b-2 ${activeMenu === "Orders" ? "border-color-13" : "border-color-3"} pb-6 cursor-pointer`}
                            onClick={() => {
                                setAuction(keplrTools.client, keplrTools.accountAddress, null)
                                setActiveMenu("Orders")
                            }}>
                            Orders
                        </div>

                    }
                </>
            }
        </div>
    )
}