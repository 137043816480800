import { coinConvert } from "@stakeordie/griptape.js";
import { Dispatch, useContext, useEffect } from "react";
import { KeplrToolsContext } from "../context/KeplrToolsContext";
import globalStores from "../stores/globalStores";
import calcTicketsWorth from "../utils/calcTicketsWorth";
import Loading from "./Loading";
import { Countdown } from "./MainAreaTimeline";

export default ({
    setActiveMenu,
    paginationValues,
    setPaginationValues
}: {
    setActiveMenu: Dispatch<string>,
    paginationValues: { page: number, page_size: number },
    setPaginationValues: Dispatch<{ page: number, page_size: number }>,
}) => {
    const keplrTools = useContext(KeplrToolsContext)
    const { auctions, auctionsTotalCount } = globalStores.factoryStore;
    const { setAuction, auctionSelected, feedAuctionsInfo, getFeedAuctionsLoading } = globalStores.auctionStore;

    if (!keplrTools || !feedAuctionsInfo || auctionSelected || !auctions || !auctionsTotalCount) return null
    return (
        <>
            {
                feedAuctionsInfo.map((auction, i) =>
                    <div className="flex flex-row w-full bg-color-2 rounded-3xl p-6 mb-5 my-4">
                        <div className=" w-3/12 border-r-2 border-color-3 self-center">
                            <div className="flex flex-col ">
                                <div className="text-color-13 text-base font-circular-std-bold">
                                    {"Raffle " + auction.configs.auction_index}
                                </div>
                                <div className="text-color-13 text-4xl font-circular-std-bold my-2">
                                    {
                                        "" + coinConvert(auction.auction_state.mint_pot, 6, 'human', 0) + " SEFI"
                                    }
                                </div>
                                <div className="text-color-8 text-xs font-circular-std-bold text-upper">
                                    Jackpot
                                </div>
                            </div>
                        </div>
                        <div className=" w-8/12 self-center">
                            {
                                (auction.auction_state.finished_status || auction.auction_state.winner_status) &&
                                <div className="flex flex-col justify-center items-center">
                                    <div>
                                        <span className="text-color-13 text-4xl font-circular-std-bold my-2">
                                            Finished
                                        </span>
                                    </div>
                                </div>
                            }
                            {
                                auction.auction_state.mint_status &&
                                <div className="flex flex-col justify-center items-center">
                                    <div>
                                        <span className="flex flex-row text-upper font-circular-std-bold text-sm text-color-13 pb-2">
                                            Pre-sale ends
                                        </span>
                                        <Countdown auctionInfo={auction} />
                                        {
                                            auction.auction_state.minted_count >= auction.configs.minimum_nft_minted ?
                                                <div className="p-1 rounded-xl text-center mt-4 inline-block" style={{ color: "#77C05D", backgroundColor: "rgba(119, 192, 93, 0.1)" }}>
                                                    <i className="fas fa-circle fa-xs mr-2"></i>Minimum ticket sales reached
                                                </div>
                                                :
                                                <div className="p-1 rounded-xl text-center mt-4 inline-block" style={{ color: "#E1AD5F", backgroundColor: "rgba(225, 173, 95, 0.1)" }}>
                                                    <i className="fas fa-circle fa-xs mr-2"></i>{(auction.configs.minimum_nft_minted - auction.auction_state.minted_count) + " More tickets need to be purchased"}
                                                </div>
                                        }
                                    </div>
                                </div>
                            }
                            {
                                auction.auction_state.reveal_status &&
                                <div className="flex flex-row justify-center items-center">
                                    <div className="flex flex-col w-1/2 items-center">
                                        <div className="flex flex-row">
                                            <div className="self-center">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="12" cy="12" r="12" fill="#454C53" />
                                                    <path d="M8 15.3333L15.3333 8M15.3333 8V14M15.3333 8H9.33333" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </div>
                                            <div className="ml-2">
                                                <div className="font-circular-std-bold text-color-13 text-xl">
                                                    {
                                                        (Math.round(((calcTicketsWorth(auction) - 1) * 100) * 100) / 100) + "%"
                                                    }
                                                </div>
                                                <div className="font-circular-std-bold text-upper text-color-8 text-base">Original Price</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col w-1/2  items-center">
                                        <div>
                                            <div className="font-circular-std-bold text-color-13 text-xl">
                                                {
                                                    auction.auction_state.minted_count - auction.auction_state.reveal_count
                                                }
                                            </div>
                                            <div className="font-circular-std-bold text-upper text-color-8 text-base">Tickets Left</div>
                                        </div>

                                    </div>
                                </div>

                            }

                        </div>
                        <div className=" w-1/12">
                            <div
                                className="flex flex-row h-full justify-center items-center font-circular-std-book text-color-11 text-xl cursor-pointer"
                                onClick={() => {
                                    setAuction(keplrTools.client, keplrTools.accountAddress, i)
                                    //setActiveMenu("")
                                }}
                            >
                                <i className="fas fa-arrow-right"></i>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                auctionsTotalCount > paginationValues.page_size &&
                <div className="flex flex-row justify-center items-center mt-8 text-color-13">
                    <i className={
                        `
                            ${(paginationValues.page * paginationValues.page_size > auctionsTotalCount) && "opacity-50 "}
                            auctionsTotalCount fas fa-arrow-left fa-lg cursor-pointer mr-4
                        `
                    }
                        onClick={() => !(paginationValues.page * paginationValues.page_size > auctionsTotalCount) &&
                            setPaginationValues({
                                ...paginationValues,
                                page: paginationValues.page + 1,
                            })}
                    >
                    </i>
                    <i
                        className={
                            `
                                        ${paginationValues.page === 0 && "opacity-50 "}
                                        fas fa-arrow-right fa-lg cursor-pointer mr-4
                                    `}
                        onClick={() => paginationValues.page !== 0 && setPaginationValues({
                            ...paginationValues,
                            page: paginationValues.page - 1,
                        })}
                    >
                    </i>
                </div>
            }
        </>
    )
}