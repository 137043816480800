import React, { useState, createContext } from "react";
import { SigningCosmWasmClient } from "secretjs";

export const KeplrToolsContext = createContext<IKeplrTools | null>(null);
export const KeplrToolsDispatchContext = createContext<Function>(() => null);

export default (props: any) => {
    const [keplrTools, setKeplrTools] = useState<IKeplrTools | null>(null)

    return (
        <KeplrToolsContext.Provider value={keplrTools}>
            <KeplrToolsDispatchContext.Provider value={setKeplrTools}>
                {props.children}
            </KeplrToolsDispatchContext.Provider>
        </KeplrToolsContext.Provider>
    )
}

export interface IKeplrTools {
    client: SigningCosmWasmClient,
    accountAddress: string,
}