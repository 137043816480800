import { coinConvert } from "@stakeordie/griptape.js";
import React, { Dispatch, useContext, useEffect, useState } from "react";
import { KeplrToolsContext } from "../context/KeplrToolsContext";
import globalStores from "../stores/globalStores";
import calcTicketsWorth from "../utils/calcTicketsWorth";
import { TxHandler } from "../utils/interfaces";
import Loading from "./Loading";

export default ({
    showClaimRewardModal,
    setShowClaimRewardModal
}: {
    showClaimRewardModal: { show: boolean, data: TxHandler | null },
    setShowClaimRewardModal: Dispatch<{ show: boolean, data: TxHandler | null }>
}) => {
    if (showClaimRewardModal.show)
        return (
            <div className="fixed z-10 inset-0 overflow-y-auto">
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity">
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                        <div className="border-color-3 inline-block align-bottom bg-color-2 rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full py-6 px-6">
                            {
                                showClaimRewardModal.data && showClaimRewardModal.data.ok === true &&
                                <>
                                    <div className="flex flex-row w-full justify-center items-center text-color-13 mb-2 mt-8">
                                        <i className="far fa-check-circle fa-5x "></i>
                                    </div>
                                    <div className="flex flex-row w-full justify-center items-center  text-color-13 my-2 font-circular-std-bold text-2xl text-center">
                                        {"You successfully claimed the reward!"}
                                    </div>
                                    <div className="flex flex-row w-full justify-center items-center mt-12">
                                        <button
                                            className="hover:bg-color-13 w-full bg-color-11 rounded-3xl shadow-2xl p-4 font-circular-std-bold text-color-1 text-sm"
                                            //@ts-ignore
                                            style={{ textAlign: "-webkit-center", marginBottom: "15px", width: "100%", height: "50px", background: "#E8E9EA", boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.25)", borderRadius: "24px" }}
                                            onClick={async () => {
                                                setShowClaimRewardModal({
                                                    show: false,
                                                    data: null
                                                })
                                            }}
                                        >
                                            Got It
                                        </button>
                                    </div>
                                </>
                            }
                            {
                                showClaimRewardModal.data && showClaimRewardModal.data.ok === false &&
                                <>
                                    <div className="flex flex-row w-full justify-center items-center text-color-13 mb-2 mt-8">
                                        <i className="far fa-times-circle fa-5x "></i>
                                    </div>
                                    <div className="flex flex-row w-full justify-center items-center  text-color-13 my-2 font-circular-std-bold text-2xl">
                                        Oh No, Something Went Wrong
                                    </div>
                                    <div className="flex flex-row w-full justify-center items-center  text-color-8 my-2 font-circular-std-bold text-base break-all">
                                        {
                                            showClaimRewardModal.data.error
                                        }
                                    </div>
                                    <div className="flex flex-row w-full justify-center items-center  mt-12">
                                        <button
                                            className="hover:bg-color-13 w-full bg-color-11 rounded-3xl shadow-2xl p-4 font-circular-std-bold text-color-1 text-sm"
                                            //@ts-ignore
                                            style={{ textAlign: "-webkit-center", marginBottom: "15px", width: "100%", height: "50px", background: "#E8E9EA", boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.25)", borderRadius: "24px" }}
                                            onClick={async () => {
                                                setShowClaimRewardModal({
                                                    show: false,
                                                    data: null
                                                })
                                            }}
                                        >
                                            Go Back
                                        </button>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div >)
    else return null
}