import { AuctionStore } from "./auctionStore";
import { FactoryStore } from "./factoryStore";
import { MyNFTsStore } from "./myNFTsStore";
import { OrderbookStore } from "./orderbookStore";
import { WalletInfoStore } from "./walletInfoStore";

export interface Stores {
    walletInfoStore: WalletInfoStore,
    factoryStore: FactoryStore,
    auctionStore: AuctionStore,
    myNFTsStore: MyNFTsStore,
    orderbookStore: OrderbookStore
}
export default {} as Stores;