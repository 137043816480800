import { coinConvert } from '@stakeordie/griptape.js';
import { off } from 'process';
import { useContext, useEffect, useState } from 'react';
import Select from 'react-select'
import { KeplrToolsContext } from '../context/KeplrToolsContext';
import globalStores from '../stores/globalStores';
import BuyTicketsOrderBookModal from './BuyTicketsOrderBookModal';
import Loading from './Loading';
import ReactTooltip from 'react-tooltip';
import calcTicketsWorth from '../utils/calcTicketsWorth';

export default () => {
    const keplrTools = useContext(KeplrToolsContext)
    const { auctions } = globalStores.factoryStore;
    const { getSefiBalance } = globalStores.walletInfoStore
    const { feedAuctionsInfo, defineAuctionSelected, auctionSelected } = globalStores.auctionStore;
    const { userOrdersState, getUserOrdersState, userOrdersStateLoading, cancelBuyOrder, addBuyOrder, addBuyOrderLoading, cancelBuyOrderLoading } = globalStores.orderbookStore;

    const [auctionToShow, setAuctionToShow] = useState<number | null>(null)
    const [buyTicketsOrderBookModal, setBuyTicketsOrderBookModal] = useState(false);

    useEffect(() => {
        if (keplrTools && feedAuctionsInfo) {
            if (!auctionToShow) {
                let revealAuction = feedAuctionsInfo.findIndex((auction) => {
                    if (auction.auction_state.reveal_status) {
                        return true
                    } else {
                        return false
                    }
                });

                if (revealAuction !== -1) {
                    setAuctionToShow(revealAuction)
                    defineAuctionSelected(revealAuction)
                    getUserOrdersState(keplrTools.client, keplrTools.accountAddress, revealAuction)
                } else {
                    setAuctionToShow(0)
                    defineAuctionSelected(0)
                    getUserOrdersState(keplrTools.client, keplrTools.accountAddress, revealAuction)
                }
            } else {
                getUserOrdersState(keplrTools.client, keplrTools.accountAddress, auctionToShow)
            }
        }
    }, [feedAuctionsInfo])

    const disableBuyButton = () => {
        if (!userOrdersState) return true
        if (!feedAuctionsInfo || !auctionSelected) return true
        if (!feedAuctionsInfo[auctionSelected[0]].auction_state.mint_status && !feedAuctionsInfo[auctionSelected[0]].auction_state.reveal_status) return true
        if (feedAuctionsInfo[auctionSelected[0]].auction_state.reveal_status) {
            if (!userOrdersState[auctionSelected[0]]) return true
            if (userOrdersState[auctionSelected[0]] && userOrdersState[auctionSelected[0]]!.buy_order_not_revealed_active) return true
        }
        return false
    }

    if (!keplrTools || !auctions || !feedAuctionsInfo || auctionToShow === null) return null
    return (
        <>
            <div className="flex flex-row w-full my-4">
                <Select
                    components={{
                        IndicatorSeparator: () => null
                    }}
                    isSearchable={false}
                    defaultValue={{ label: "Raffle " + feedAuctionsInfo[auctionToShow].configs.auction_index, value: auctionToShow }}
                    onChange={(e: any) => {
                        if (!userOrdersState[e!.value]) {
                            getUserOrdersState(keplrTools.client, keplrTools.accountAddress, e!.value)
                        }
                        setAuctionToShow(e!.value)
                        defineAuctionSelected(e!.value)
                    }}
                    isDisabled={userOrdersStateLoading}
                    styles={{
                        singleValue: (base, state) => ({
                            ...base,
                            color: "white",
                        }),
                        control: (base, state) => ({
                            ...base,
                            border: "1px solid #A2A5A9",
                            borderRadius: "24px",
                            background: "transparent",
                            color: "white",
                            boxShadow: 'none',
                            '&:hover': {
                                border: '1px solid #A2A5A9',
                            },
                            padding: "0.25rem"
                        }),
                        menu: provided => ({
                            ...provided,
                            background: "#1D262F",
                        }),
                        menuList: provided => ({
                            ...provided,
                            color: 'white',
                        }),
                        option: provided => ({
                            ...provided,
                            backgroundColor: "#1D262F",
                            '&:hover': {
                                background: "#8A8F93",
                            },
                        }),
                        dropdownIndicator: base => ({
                            ...base,
                            color: "white"
                        }),
                        input: base => ({
                            ...base,
                            color: "white"
                        }),
                    }}
                    placeholder="Select..."
                    //@ts-ignore
                    options={feedAuctionsInfo.map((auction, i) => {
                        return ({
                            value: i, label: "Raffle " + auction.configs.auction_index
                        })
                    })
                    } />
                {
                    feedAuctionsInfo[auctionToShow].auction_state.reveal_status &&
                    <div className='ml-auto'>
                        <a data-tip="You can only have 1 active buy order at a time!">
                            <button
                                className='bg-color-11 shadow-2xl rounded-3xl p-4 font-circular-std-bold text-sm text-color-1  w-48 hover:bg-color-13 disabled:opacity-50 text-center'
                                disabled={disableBuyButton()}
                                onClick={() => setBuyTicketsOrderBookModal(true)}
                            >
                                {
                                    addBuyOrderLoading ? <Loading variantBlack={true} /> : "Create Buy Order"
                                }
                            </button>
                        </a>
                        {
                            auctionSelected &&
                            feedAuctionsInfo[auctionSelected[0]].auction_state.reveal_status &&
                            userOrdersState[auctionSelected[0]] && userOrdersState[auctionSelected[0]]!.buy_order_not_revealed_active &&
                            <ReactTooltip
                                place="top"
                                backgroundColor='#2D353E'
                                textColor='#FFFFFF'
                                className='custom-color'
                                effect='solid'
                                class="font-circular-std-bold"
                                offset={{ top: 10 }}
                            />
                        }

                    </div>
                }
            </div>
            {
                userOrdersStateLoading && <Loading variantBlack={false} />
            }
            {
                !userOrdersStateLoading &&
                userOrdersState[auctionToShow] &&
                userOrdersState[auctionToShow]!.buy_order_not_revealed_active &&
                <div>
                    <h3 className='text-color-11 text-xl font-circular-std-bold py-2'>Active Buy Orders</h3>
                    <table className="w-full">
                        <thead className="flex flex-row w-full my-4 px-4">
                            <tr
                                className={
                                    `w-2/12 font-circular-std-bold text-color-8 text-upper text-xs`
                                }>

                            </tr>
                            <tr className={
                                `w-2/12 font-circular-std-bold text-color-8 text-upper text-xs`
                            }>
                                Status
                            </tr>
                            <tr className={
                                `w-3/12 font-circular-std-bold text-color-8 text-upper text-xs`
                            }>
                                Ticket Amount
                            </tr>

                            <tr className={"w-3/12 font-circular-std-bold text-color-8 text-upper text-xs"}>
                                Bid Per Ticket
                            </tr>

                            <tr className={"w-3/12 font-circular-std-bold text-color-8 text-upper text-xs"}>
                                Current Value
                            </tr>
                        </thead>
                        <tbody className="w-full">
                            {
                                userOrdersState[auctionToShow]!.buy_order_not_revealed_active &&
                                <tr className='flex flex-row bg-color-2 p-4 rounded-2xl mb-4    '>
                                    <td
                                        className={
                                            `w-2/12 text-color-13 font-circular-std-bold text-base`
                                        }>
                                        <button
                                            className="bg-color-red shadow-2xl rounded-lg p-2 font-circular-std-bold text-sm text-color-13 hover:opacity-90"
                                            disabled={cancelBuyOrderLoading}
                                            onClick={async () => {
                                                await cancelBuyOrder(
                                                    keplrTools.client,
                                                    userOrdersState[auctionToShow]!.buy_order_not_revealed_active!.price,
                                                    10,
                                                    false,
                                                    userOrdersState[auctionToShow]!.buy_order_not_revealed_active!.id,
                                                    auctionToShow
                                                )
                                                await getUserOrdersState(keplrTools.client, keplrTools.accountAddress, auctionToShow)
                                                await getSefiBalance(keplrTools.client, keplrTools.accountAddress);
                                            }}
                                        >
                                            {
                                                cancelBuyOrderLoading ? <Loading variantBlack={false} /> : "Cancel"
                                            }
                                        </button>

                                    </td>
                                    <td
                                        className={
                                            `w-2/12 text-color-13 font-circular-std-bold text-base self-center`
                                        }>
                                        <span><i className="fas fa-circle fa-xs mr-2" style={{ color: "#14AC3F" }}></i> Active</span>
                                    </td>
                                    <td
                                        className={
                                            `w-3/12 text-color-13 font-circular-std-bold text-base self-center`
                                        }>
                                        {userOrdersState[auctionToShow]!.buy_order_not_revealed_active!.buy_nft_amount}
                                    </td>
                                    <td
                                        className={
                                            `w-3/12 text-color-13 font-circular-std-bold text-base self-center`
                                        }>
                                        {coinConvert(Math.round(parseInt(userOrdersState[auctionToShow]!.buy_order_not_revealed_active!.price)), 6, 'human', 0) + " SEFI"}
                                    </td>
                                    <td
                                        className={
                                            `w-3/12 text-color-13 font-circular-std-bold text-base self-center`
                                        }>
                                        {
                                            (coinConvert((parseInt(feedAuctionsInfo[auctionToShow].configs.nft_price) * calcTicketsWorth(feedAuctionsInfo[auctionToShow])).toString(), 6, 'human', 0)) + " SEFI"
                                        }
                                    </td>
                                </tr>
                            }

                        </tbody>
                    </table>
                </div>

            }
            {
                // Buy Side Table
                auctionToShow !== null &&
                !userOrdersStateLoading &&
                userOrdersState[auctionToShow] &&
                userOrdersState[auctionToShow]!.user_order_history.length > 0 &&
                userOrdersState[auctionToShow]!.user_order_history.find((order) => order.snip20_sent) &&
                <div>
                    <div className="border-1 border-color-4 my-1" />
                    <h3 className='text-color-11 text-xl font-circular-std-bold py-2'>Completed Buy Orders</h3>
                    <table className="w-full">
                        <thead className="flex flex-row w-full my-4 px-4">
                            <tr
                                className={
                                    `w-4/12 font-circular-std-bold text-color-8 text-upper text-xs`
                                }>
                                Date
                            </tr>
                            <tr className={
                                `w-2/12 font-circular-std-bold text-color-8 text-upper text-xs`
                            }>
                                Ticket Amount
                            </tr>
                            <tr className={
                                `w-3/12 font-circular-std-bold text-color-8 text-upper text-xs`
                            }>
                                Ticket Price
                            </tr>
                            <tr className={
                                `w-3/12 font-circular-std-bold text-color-8 text-upper text-xs`
                            }>
                                Total SEFI Sent
                            </tr>
                        </thead>
                        <tbody className="w-full">
                            {
                                userOrdersState[auctionToShow]!.user_order_history
                                    .filter((order) => order.snip20_sent)
                                    .map((order) =>
                                        <tr className='flex flex-row bg-color-2 p-4 rounded-2xl mb-4'>
                                            <td
                                                className={
                                                    `w-4/12 text-color-13 font-circular-std-bold text-base`
                                                }>
                                                {
                                                    new Date(order.block_time * 1000).toLocaleString()
                                                }
                                            </td>
                                            <td
                                                className={
                                                    `w-2/12 text-color-13 font-circular-std-bold text-base`
                                                }>
                                                {
                                                    order.bought_nft_count
                                                }
                                            </td>
                                            <td
                                                className={
                                                    `w-3/12 text-color-13 font-circular-std-bold text-base`
                                                }>
                                                {
                                                    coinConvert(Math.round(parseInt(order.price_per_nft)), 6, 'human', 0) + " SEFI"
                                                }
                                            </td>
                                            <td
                                                className={
                                                    `w-3/12 text-color-13 font-circular-std-bold text-base`
                                                }>
                                                {
                                                    coinConvert(Math.round(parseInt(order.snip20_sent!)), 6, 'human', 0) + " SEFI"
                                                }
                                            </td>
                                        </tr>
                                    )
                            }
                        </tbody>
                    </table>
                </div>
            }
            {
                // Buy Side Table
                auctionToShow !== null &&
                !userOrdersStateLoading &&
                userOrdersState[auctionToShow] &&
                userOrdersState[auctionToShow]!.user_order_history.length > 0 &&
                userOrdersState[auctionToShow]!.user_order_history.find((order) => order.snip20_received) &&
                <div>
                    <div className="border-1 border-color-4 my-1" />
                    <h3 className='text-color-11 text-xl font-circular-std-bold  py-2'>Completed Sell Orders</h3>
                    <table className="w-full">
                        <thead className="flex flex-row w-full my-4 px-4">
                            <tr
                                className={
                                    `w-4/12 font-circular-std-bold text-color-8 text-upper text-xs`
                                }>
                                Date
                            </tr>
                            <tr className={
                                `w-2/12 font-circular-std-bold text-color-8 text-upper text-xs`
                            }>
                                Ticket Amount
                            </tr>
                            <tr className={
                                `w-2/12 font-circular-std-bold text-color-8 text-upper text-xs`
                            }>
                                Ticket Price
                            </tr>
                            <tr className={
                                `w-2/12 font-circular-std-bold text-color-8 text-upper text-xs`
                            }>
                                Fees
                            </tr>
                            <tr className={
                                `w-2/12 font-circular-std-bold text-color-8 text-upper text-xs`
                            }>
                                Total SEFI Received
                            </tr>
                        </thead>
                        <tbody className="w-full">
                            {
                                userOrdersState[auctionToShow]!.user_order_history
                                    .filter((order) => order.snip20_received)
                                    .map((order) =>
                                        <tr className='flex flex-row bg-color-2 p-4 rounded-2xl mb-4'>
                                            <td
                                                className={
                                                    `w-4/12 text-color-13 font-circular-std-bold text-base`
                                                }>
                                                {
                                                    new Date(order.block_time * 1000).toLocaleString()
                                                }
                                            </td>
                                            <td
                                                className={
                                                    `w-2/12 text-color-13 font-circular-std-bold text-base`
                                                }>
                                                {
                                                    order.sold_nft_count
                                                }
                                            </td>
                                            <td
                                                className={
                                                    `w-2/12 text-color-13 font-circular-std-bold text-base`
                                                }>
                                                {
                                                    coinConvert(Math.round(parseInt(order.price_per_nft)), 6, 'human', 0) + " SEFI"
                                                }
                                            </td>
                                            <td
                                                className={
                                                    `w-2/12 text-color-13 font-circular-std-bold text-base`
                                                }>
                                                {
                                                    coinConvert(Math.round(parseInt(order.royalties!)), 6, 'human', 0) + " SEFI"
                                                }
                                            </td>
                                            <td
                                                className={
                                                    `w-2/12 text-color-13 font-circular-std-bold text-base`
                                                }>
                                                {
                                                    coinConvert(Math.round(parseInt(order.snip20_received!)), 6, 'human', 0) + " SEFI"
                                                }
                                            </td>
                                        </tr>
                                    )
                            }
                        </tbody>
                    </table>
                </div>
            }
            {
                auctionToShow !== null &&
                !userOrdersStateLoading &&
                userOrdersState[auctionToShow] &&
                (!userOrdersState[auctionToShow]!.buy_order_not_revealed_active &&
                    userOrdersState[auctionToShow]!.user_order_history.length === 0) &&
                <div className="text-xl text-color-13 mt-6">
                    No orders for this raffle...
                </div>
            }
            <BuyTicketsOrderBookModal
                buyTicketsOrderBookModal={buyTicketsOrderBookModal}
                setBuyTicketsOrderBookModal={setBuyTicketsOrderBookModal}
            />
        </>

    )
}