export default ({
    variantBlack
}: {
    variantBlack: boolean | null
}) => <div className="flex flex-row justify-center items-center">
        <div className={
            `
            ${variantBlack ? "border-color-1" : "border-color-13"}
            animate-spin rounded-full h-6 w-6 border-t-2 border-b-2
        `
        }></div>
    </div>

