import { useContext, useState } from "react";
import { KeplrToolsContext } from "../context/KeplrToolsContext";
import globalStores from "../stores/globalStores";
import calcTicketsWorth from "../utils/calcTicketsWorth";
import { TxHandler } from "../utils/interfaces";
import Loading from "./Loading";
import SellTicketsOrderBookModal from "./SellTicketsOrderBookModal";
import ViewMyNFTsModal from "./ViewMyNFTsModal";
import ClaimRewardsModal from "./ClaimRewardsModal"

export default () => {
    const keplrTools = useContext(KeplrToolsContext)
    const { getSefiBalance } = globalStores.walletInfoStore;
    const { auctionSelected, feedAuctionsInfo } = globalStores.auctionStore
    const { myNFTs, claimRewards, claimRewardsLoading } = globalStores.myNFTsStore;
    const [myNFTsModal, setMyNFTsModal] = useState<boolean>(false);
    const [sellTicketsOrderBookModal, setSellTicketsOrderBookModal] = useState(false);
    const [showClaimRewardModal, setShowClaimRewardModal] = useState<{ show: boolean, data: TxHandler | null }>({
        show: false,
        data: null
    });

    if (!myNFTs || !feedAuctionsInfo || !auctionSelected || !keplrTools) return null

    const calcMyActiveTickets = () => {
        if (!myNFTs[auctionSelected[0]]) return 0
        let activeCount = 0;
        myNFTs[auctionSelected[0]]!.forEach((myNFT) => myNFT && feedAuctionsInfo[auctionSelected[0]].in_play_tokens.includes(myNFT) && activeCount++)
        return activeCount
    }

    if (
        feedAuctionsInfo[auctionSelected[0]].auction_state.winner_id &&
        myNFTs[auctionSelected[0]] &&
        myNFTs[auctionSelected[0]]!.includes(feedAuctionsInfo[auctionSelected[0]].auction_state.winner_id!)
    ) {
        return (
            <div className="flex flex-row w-full bg-color-3 rounded-3xl p-6 mb-5">
                <div className=" w-1/12">
                    <div className="flex justify-center items-center w-full h-full">
                        <img src={require("../assets/ticket.png").default} />
                    </div>
                </div>
                <div className=" w-8/12">
                    <div className="flex items-center w-full h-full">
                        <div className="text-color-13 font-circular-std-bold text-xl">
                            {
                                feedAuctionsInfo[auctionSelected[0]].auction_state.finished_status ?
                                    "Congratulations! You have claimed the rewards!"
                                    :
                                    "Congratulations! You have the winning ticket!"
                            }
                        </div>
                    </div>
                </div>
                <div className=" w-3/12">
                    <div className="flex justify-end w-full h-full" >
                        {
                            feedAuctionsInfo[auctionSelected[0]].auction_state.finished_status &&
                            <div
                                className="flex flex-row h-full justify-end items-center font-circular-std-book text-color-11 text-base cursor-pointer"
                                onClick={() => setMyNFTsModal(true)}
                            >
                                View Tickets
                                <i className="fas fa-arrow-right ml-3"></i>
                            </div>
                        }
                        {
                            feedAuctionsInfo[auctionSelected[0]].auction_state.winner_status &&
                            <button
                                onClick={async () => {
                                    const res = await claimRewards(keplrTools.client, feedAuctionsInfo[auctionSelected[0]].auction_state.winner_id!)
                                    if (res.ok) {
                                        getSefiBalance(keplrTools.client, keplrTools.accountAddress)
                                    }
                                    setShowClaimRewardModal({
                                        show: true,
                                        data: res
                                    })
                                }}
                                disabled={feedAuctionsInfo[auctionSelected[0]].auction_state.finished_status}
                                className="font-circular-std-bold text-base text-color-1 bg-color-11 rounded-3xl shadow-2xl py-4 px-8"
                            > {
                                    claimRewardsLoading ? <Loading variantBlack={true} /> : "Claim Reward"
                                }</button>
                        }
                    </div>
                </div>
                <ViewMyNFTsModal
                    myNFTsModal={myNFTsModal}
                    setMyNFTsModal={setMyNFTsModal}
                />
                <ClaimRewardsModal
                    showClaimRewardModal={showClaimRewardModal}
                    setShowClaimRewardModal={setShowClaimRewardModal}
                />
            </div>
        )
    } else {
        return (
            <div className="flex flex-row w-full bg-color-3 rounded-3xl p-6 mb-5">
                <div className=" w-1/12">
                    <div className="flex justify-center items-center w-full h-full">
                        <img src={require("../assets/ticket.png").default} />
                    </div>
                </div>
                <div className=" w-8/12 self-center">
                    <div className="flex flex-col justify-center">
                        <div className="text-color-13 font-circular-std-bold text-xl">
                            {
                                feedAuctionsInfo[auctionSelected[0]].auction_state.reveal_count === 0 ?
                                    myNFTs[auctionSelected[0]]!.length + " Tickets Entered" :
                                    calcMyActiveTickets() > 0 ?
                                        "Your tickets are worth " + calcTicketsWorth(feedAuctionsInfo[auctionSelected[0]]) + "x" :
                                        "Dang! All of Your Tickets Lost"
                            }
                        </div>
                        <div className="text-color-9 font-circular-std-book text-sm">
                            {
                                feedAuctionsInfo[auctionSelected[0]].auction_state.reveal_count === 0 ?
                                    "You're in the running!" :
                                    calcMyActiveTickets() > 0 ?
                                        "You have " + calcMyActiveTickets() + " active tickets!" :
                                        feedAuctionsInfo[auctionSelected[0]].auction_state.reveal_status ?
                                            "You still have time to buy more!" : ""
                            }

                        </div>
                    </div>
                </div>
                <div className=" w-3/12">
                    <div className="flex flex-col justify-end w-full h-full" >
                        {
                            feedAuctionsInfo[auctionSelected[0]].auction_state.reveal_status &&
                            calcMyActiveTickets() > 0 &&
                            <button
                                onClick={() => setSellTicketsOrderBookModal(true)}
                                disabled={feedAuctionsInfo[auctionSelected[0]].auction_state.finished_status}
                                className="font-circular-std-bold text-base text-color-1 bg-color-11 rounded-3xl shadow-2xl py-4 px-2 mb-8 w-5/6 self-end"
                            > Sell Tickets</button>
                        }
                        <div
                            className="flex flex-row h-full justify-end items-center font-circular-std-book text-color-11 text-base cursor-pointer"
                            onClick={() => setMyNFTsModal(true)}
                        >
                            View Tickets
                            <i className="fas fa-arrow-right ml-3"></i>
                        </div>
                    </div>
                </div>
                <ViewMyNFTsModal
                    myNFTsModal={myNFTsModal}
                    setMyNFTsModal={setMyNFTsModal}
                />
                <SellTicketsOrderBookModal
                    sellTicketsOrderBookModal={sellTicketsOrderBookModal}
                    setSellTicketsOrderBookModal={setSellTicketsOrderBookModal}
                />

            </div>
        )
    }
}