import { Dispatch, useState } from 'react';
import { SigningCosmWasmClient } from 'secretjs';
import entropy from '../utils/entropy';
import erroTxHandler from '../utils/erroTxHandler';
import { TxHandler } from '../utils/interfaces';
import { SecretContract } from './factoryStore';
import globalStores from './globalStores';

export interface AuctionStore {
  clearState(): null;
  auctionSelected: [number, string] | null;
  setAuction(client: SigningCosmWasmClient, myAddress: string, auctionIndex: number | null): Promise<boolean>;
  defineAuctionSelected(auctionIndex: number | null): boolean;
  mintLoading: boolean;
  mintNFTs(client: SigningCosmWasmClient, count: number, totalToPay: string, myAddress: string): Promise<TxHandler>;
  setAuctionToRevealStatus(client: SigningCosmWasmClient): Promise<void>;
  changeToRevealLoading: boolean;
  revealLosers(client: SigningCosmWasmClient): Promise<void>;
  revealLosersLoading: boolean;
  getFeedAuctions(client: SigningCosmWasmClient, auctions: SecretContract[]): Promise<void>;
  getFeedAuctionsLoading: boolean;
  feedAuctionsInfo: AuctionInfo[] | null;
}

export function useAuctionStore() {
  const [feedAuctionsInfo, setFeedAuctionsInfo] = useState<AuctionInfo[] | null>(null);
  const [auctionSelected, setAuctionSelected] = useState<[number, string] | null>(null);
  const [mintLoading, setMintLoading] = useState<boolean>(false);
  const [changeToRevealLoading, setChangeToRevealLoading] = useState<boolean>(false);

  const clearState = () => {
    setFeedAuctionsInfo(null);
    setAuctionSelected(null);
    const { clearState: myNFTsStoreClearState } = globalStores.myNFTsStore;
    myNFTsStoreClearState();
    const { clearState: orderbookStoreClearState } = globalStores.orderbookStore;
    orderbookStoreClearState();
    return null;
  };

  const setAuction = async (client: SigningCosmWasmClient, myAddress: string, auctionIndex: number | null) => {
    const { auctions } = globalStores.factoryStore;
    if (!auctions || !feedAuctionsInfo) return false;
    setAuctionSelected(auctionIndex === null ? null : [auctionIndex, auctions[auctionIndex].address]);
    if (auctionIndex !== null) {
      const { getMyNFTs, myNFTs } = globalStores.myNFTsStore;
      const { getUserOrdersState } = globalStores.orderbookStore;
      if (!myNFTs[auctionIndex]) {
        getMyNFTs(client, myAddress, feedAuctionsInfo[auctionIndex].configs.nfts_contract.contract!.address, auctionIndex);
        if (feedAuctionsInfo[auctionIndex].auction_state.reveal_status) getUserOrdersState(client, myAddress, auctionIndex);
      }
    }
    return true;
  };

  const defineAuctionSelected = (auctionIndex: number | null) => {
    const { auctions } = globalStores.factoryStore;
    if (!auctions || !feedAuctionsInfo) return false;
    setAuctionSelected(auctionIndex === null ? null : [auctionIndex, auctions[auctionIndex].address]);
    return true;
  };

  const [getFeedAuctionsLoading, setGetFeedAuctionsLoading] = useState(false);

  const getFeedAuctions = async (client: SigningCosmWasmClient, auctions: SecretContract[]) => {
    setGetFeedAuctionsLoading(true);

    let promises = [];

    for (let auction of auctions) {
      promises.push(
        client.queryContractSmart(auction.address, {
          get_auction_info: {}
        })
      );
    }

    const responses = await Promise.all(promises);
    if (feedAuctionsInfo && feedAuctionsInfo.length > 0 && responses.length > 0 && feedAuctionsInfo[0].configs.auction_index !== responses[0].get_auction_info.configs.auction_index) {
      setAuctionSelected(null);
    }
    setFeedAuctionsInfo(responses.map(auction => auction.get_auction_info));
    setGetFeedAuctionsLoading(false);
  };

  const mintNFTs = async (client: SigningCosmWasmClient, count: number, totalToPay: string, myAddress: string) => {
    if (!auctionSelected || !feedAuctionsInfo) throw Error('!auctionSelected || !feedAuctionsInfo');

    setMintLoading(true);
    try {
      const { auctions } = globalStores.factoryStore;
      if (!auctions || !feedAuctionsInfo) throw new Error();

      let auctioIndex = auctionSelected[0];

      const fee = {
        amount: [{ amount: '300000', denom: 'uscrt' }],
        gas: '' + Math.round(Math.ceil(11500 * count + 95000))
      };
      await client.execute(
        globalThis.config.SEFI_CONTRACT_ADDRESS,
        {
          send: {
            recipient: auctionSelected[1],
            amount: totalToPay,
            msg: btoa(
              JSON.stringify({
                mint_nfts: {
                  count: count,
                  entropy: entropy(27)
                }
              })
            )
          }
        },
        undefined,
        undefined,
        fee
      );

      // get auctionInfo for only this and updated the feedAuctions state...
      let updatedFeedAuctionsInfo = [...feedAuctionsInfo];
      const newAuctionInfo = await client.queryContractSmart(auctionSelected[1], {
        get_auction_info: {}
      });
      updatedFeedAuctionsInfo[auctioIndex] = newAuctionInfo.get_auction_info;
      setFeedAuctionsInfo(updatedFeedAuctionsInfo);

      // get My NFTs
      const { getMyNFTs } = globalStores.myNFTsStore;
      await getMyNFTs(client, myAddress, feedAuctionsInfo[auctioIndex].configs.nfts_contract.contract!.address, auctioIndex);
    } catch (e: any) {
      //notifications(e.message, "danger");
      setMintLoading(false);
      console.log(e);
      return {
        ok: false,
        error: erroTxHandler(e.message)
      };
    }
    setMintLoading(false);
    return {
      ok: true
    };
  };

  const setAuctionToRevealStatus = async (client: SigningCosmWasmClient) => {
    const { auctions } = globalStores.factoryStore;
    if (!auctionSelected || !feedAuctionsInfo || !auctions) throw Error('!auctionSelected || !auctionInfo||!auctions');

    setChangeToRevealLoading(true);
    try {
      const fee = {
        amount: [{ amount: '500000', denom: 'uscrt' }],
        gas: '' + Math.round(Math.ceil(0.38 * feedAuctionsInfo[auctionSelected[0]].auction_state.minted_count + 650) * 1000) // y = 0.38x + 650
      };
      await client.execute(auctionSelected[1], { change_mint_to_reveal_status: {} }, undefined, undefined, fee);
      await getFeedAuctions(client, auctions);
    } catch (e: any) {
      //notifications(e.message, "danger");
    }
    setChangeToRevealLoading(false);
  };

  const [revealLosersLoading, setRevealLosersLoading] = useState<boolean>(false);
  const revealLosers = async (client: SigningCosmWasmClient) => {
    const { auctions } = globalStores.factoryStore;
    if (!auctionSelected || !feedAuctionsInfo || !auctions) throw Error('!auctionSelected || !auctionInfo || !auctions');
    setRevealLosersLoading(true);
    try {
      const gas = calcRevealLosersGas(feedAuctionsInfo[auctionSelected[0]].durations, feedAuctionsInfo[auctionSelected[0]].in_play_tokens.length);
      const fee = {
        amount: [{ amount: '500000', denom: 'uscrt' }],
        gas // TODO
      };
      await client.execute(auctionSelected[1], { reveal_losers: { entropy: entropy(27) } }, undefined, undefined, fee);
      await getFeedAuctions(client, auctions);
    } catch (e: any) {
      console.log(e);
      //notifications(e.message, "danger");
    }
    setRevealLosersLoading(false);
  };

  return {
    clearState,
    defineAuctionSelected,
    auctionSelected,
    mintLoading,
    changeToRevealLoading,
    setAuction,
    mintNFTs,
    setAuctionToRevealStatus,
    revealLosers,
    revealLosersLoading,
    getFeedAuctions,
    getFeedAuctionsLoading,
    feedAuctionsInfo
  };
}

export interface AuctionInfo {
  configs: {
    factory: SecretContract;
    nfts_contract: ContractInfo;
    order_book_contract: ContractInfo;
    dao_contract: SecretContract;
    token_contract: SecretContract;
    staking_contract: SecretContract;
    auction_index: number;
    auction_label: string;
    nft_price: string;
    is_stopped: boolean;
    minimum_nft_minted: number;
  };
  auction_state: AuctionState;
  durations: DurationsStructure;
  in_play_tokens: string[];
}
export interface AuctionState {
  finished_status: boolean;
  mint_pot: string;
  mint_status: boolean;
  minted_count: number;
  reveal_count: number;
  reveal_status: boolean;
  winner_id: string | null;
  winner_status: boolean;
}

export interface ContractInfo {
  contract: SecretContract | null;
  info: {
    code_id: number;
    code_hash: string;
  };
}
export interface DurationsStructure {
  mint_start: number;
  mint_expected_duration: number;
  mint_end: number | null;
  next_reveal: number | null;
  reveals: Reveal[];
}

export interface Reveal {
  reveal_expected_duration: number;
  reveal_count_per: number;
  reveal_expected_execution_time: number | null;
  reveal_excution_time: number | null;
}

const calcRevealLosersGas = (durations: DurationsStructure, totalCount: number) => {
  if (durations.next_reveal === null || durations.next_reveal === undefined) throw Error('Gas Calc Error');
  let countToReveal = totalCount * durations.reveals[durations.next_reveal].reveal_count_per * 0.01;

  if (durations.next_reveal === durations.reveals.length - 1) {
    // It means we are going to winner status
    return '' + (110 * countToReveal + 570000) * 10;
  } else {
    return '' + (550 * countToReveal + 200000) * 10;
  }
};
