import { coinConvert } from "@stakeordie/griptape.js"
import React, { useContext, useEffect, useState } from "react"
import { KeplrToolsContext } from "../context/KeplrToolsContext"
import globalStores from "../stores/globalStores"
import Feed from "./Feed"
import GeneralTopRow from "./GeneralTopRow"
import Loading from "./Loading"
import MainAreaTimeline from "./MainAreaTimeline"
import NavBar from "./NavBar"
import Orders from "./Orders"

export default () => {
    const keplrTools = useContext(KeplrToolsContext)
    const [activeMenu, setActiveMenu] = useState("Feed")
    const [paginationValues, setPaginationValues] = useState({
        page: 0,
        page_size: 2
    });
    const [intervalID, setIntervalID] = useState<NodeJS.Timeout>();
    const { auctions, auctionsLoading, getAuctions, createUserViewingKeyLoading, createUserViewingKey, getUserViewingKey } = globalStores.factoryStore;
    const { setAuction, auctionSelected, getFeedAuctionsLoading } = globalStores.auctionStore;

    useEffect(() => {
        if (keplrTools) {
            getAuctions(keplrTools.client, keplrTools.accountAddress, null, paginationValues.page, paginationValues.page_size, false)
            if (paginationValues.page === 0) {
                let id = setInterval(() => {
                    getAuctions(keplrTools.client, keplrTools.accountAddress, null, paginationValues.page, paginationValues.page_size, false)
                }, 60000)
                setIntervalID(id)
            }
        }
    }, [keplrTools])

    useEffect(() => {
        if (keplrTools) {
            getAuctions(keplrTools.client, keplrTools.accountAddress, null, paginationValues.page, paginationValues.page_size, true)
            if (intervalID) clearInterval(intervalID)
            if (paginationValues.page === 0) {
                let id = setInterval(() => {
                    getAuctions(keplrTools.client, keplrTools.accountAddress, null, paginationValues.page, paginationValues.page_size, false)
                }, 30000)
                setIntervalID(id)
            }
        }
    }, [paginationValues])

    if (!keplrTools) {
        return (
            <div className="flex flex-col  w-full">
                <NavBar
                    activeMenu={activeMenu}
                    setActiveMenu={setActiveMenu}
                    noItems={true}
                />
                <div className="font-circular-std-bold text-xl text-color-11 py-4 px-8 text-center">
                    Please connect your Keplr wallet to access Revealed.Network
                </div>
            </div>
        )
    }
    else if (!getUserViewingKey(keplrTools.accountAddress)) {
        return (
            <div className="flex flex-col  w-full">
                <NavBar
                    activeMenu={activeMenu}
                    setActiveMenu={setActiveMenu}
                    noItems={true}
                />
                <button
                    onClick={() => createUserViewingKey(keplrTools.client, keplrTools.accountAddress)}
                    disabled={createUserViewingKeyLoading}
                    className="font-circular-std-bold text-base text-color-1 bg-color-11 rounded-3xl shadow-2xl py-4 px-8"
                > {
                        createUserViewingKeyLoading ? <Loading variantBlack={true} /> : "Get started with Revealed.network"
                    }</button>
                <div className="font-circular-std-book text-base text-color-9 my-4 text-center">
                    You will be required to approve a transaction to enter the application
                </div>
            </div>
        )
    } else {
        return (
            <div className="flex flex-col  w-full">
                <NavBar
                    activeMenu={activeMenu}
                    setActiveMenu={setActiveMenu}
                    noItems={false}
                />
                {
                    activeMenu === "Feed" && !auctionSelected &&
                    <Feed
                        setActiveMenu={setActiveMenu}
                        paginationValues={paginationValues}
                        setPaginationValues={setPaginationValues}
                    />
                }
                {
                    activeMenu === "Feed" && auctionSelected &&
                    <>
                        <GeneralTopRow
                            setActiveMenu={setActiveMenu}
                        />
                        <MainAreaTimeline />
                    </>
                }
                {
                    activeMenu === "Feed" && (auctionsLoading || getFeedAuctionsLoading) &&
                    <div className="my-4">
                        <Loading variantBlack={false} />
                    </div>
                }
                {
                    activeMenu === "Orders" && <Orders />
                }
            </div>
        )
    }
}