import { useState } from 'react';
import { SigningCosmWasmClient } from 'secretjs';
import { fromUtf8 } from "@iov/encoding";
import entropy from '../utils/entropy';
import globalStores from './globalStores';
export interface FactoryStore {
    auctionsTotalCount: number | null,
    auctions: SecretContract[] | null,
    auctionsStatus: string | null,
    auctionsLoading: boolean;
    getAuctions(client: SigningCosmWasmClient, myAddress: string, status: string | null, page: number, page_size: number, changed_page: boolean): Promise<void>,
    createAuctionLoading: boolean;
    createAuction(client: SigningCosmWasmClient): Promise<void>,
    createUserViewingKeyLoading: boolean,
    createUserViewingKey(client: SigningCosmWasmClient, address: string): Promise<void>,
    setUserViewingKey(address: string, key: string): null,
    getUserViewingKey(address: string): string,
}

export function useFactoryStore() {
    const [auctionsStatus, setAuctionsStatus] = useState<string | null>(null);
    const [auctions, setAuctions] = useState<SecretContract[] | null>(null);
    const [auctionsTotalCount, setAuctionsTotalCount] = useState<number | null>(null);
    const [auctionsLoading, setAuctionsLoading] = useState(false);

    const getAuctions = async (client: SigningCosmWasmClient, myAddress: string, status: string | null, page: number, page_size: number, changed_page: boolean) => {
        setAuctionsLoading(true)
        if (changed_page) {
            const { clearState } = globalStores.auctionStore;
            clearState()
        }
        const res = await client.queryContractSmart(globalThis.config.FACTORY_CONTRACT_ADDRESS, {
            get_auctions: { status, page, page_size }
        })
        setAuctions(res.get_auctions.auctions)
        const { getFeedAuctions, setAuction } = globalStores.auctionStore;
        setAuctionsTotalCount(res.get_auctions.total_count)
        getFeedAuctions(client, res.get_auctions.auctions)
        setAuctionsStatus(status);
        setAuctionsLoading(false)
    }

    const [createAuctionLoading, setCreateAuctionLoading] = useState(false);

    const createAuction = async (client: SigningCosmWasmClient) => {
        setCreateAuctionLoading(true)
        try {
            //await client.execute(process.env.REACT_APP_FACTORY_CONTRACT_ADDRESS, { create_auction: { prng_seed: btoa(entropy(27)) } });
            //getAuctions(client, "mint;reveal;winner")
        } catch (e) {
            console.log(e)
        }
        setCreateAuctionLoading(false)
    }

    const [createUserViewingKeyLoading, setCreateUserViewingKeyLoading] = useState(false);

    const createUserViewingKey = async (client: SigningCosmWasmClient, address: string) => {
        setCreateUserViewingKeyLoading(true)
        try {
            const fee = {
                amount: [{ amount: "300000", denom: "uscrt" }],
                gas: "27500"
            };

            const res = await client.execute(globalThis.config.FACTORY_CONTRACT_ADDRESS, { create_viewing_key: { entropy: btoa(entropy(27)) } }, undefined, undefined, fee);
            setUserViewingKey(address, JSON.parse(fromUtf8(res.data)).create_viewing_key.key)
        } catch (e) {
            console.log(e)
        }
        setCreateUserViewingKeyLoading(false)
    }

    const setUserViewingKey = (address: string, key: string) => {
        localStorage.setItem("vk_" + globalThis.config.FACTORY_CONTRACT_ADDRESS + "_" + address, key)
        return null
    }

    const getUserViewingKey = (address: string) => {
        return localStorage.getItem("vk_" + globalThis.config.FACTORY_CONTRACT_ADDRESS + "_" + address) || ""
    }

    return { auctionsTotalCount, auctionsStatus, auctions, auctionsLoading, getAuctions, createAuctionLoading, createAuction, getUserViewingKey, setUserViewingKey, createUserViewingKey, createUserViewingKeyLoading };
}

export interface SecretContract {
    address: string,
    contract_hash: string
}