import { useState } from 'react';
import { coinConvert } from '@stakeordie/griptape.js';
import { Account, SigningCosmWasmClient } from 'secretjs';

export interface WalletInfoStore {
  scrtBalance: string | null;
  loadingScrtBalance: boolean;
  sefiBalance: string | null;
  loadingSefiBalance: boolean;
  getScrtBalance(client: SigningCosmWasmClient, address: string): Promise<void>;
  getSefiBalance(client: SigningCosmWasmClient, address: string): Promise<void>;
}

export function useWalletInfoStore() {
  const [scrtBalance, setScrtBalance] = useState<string | null>(null);
  const [loadingScrtBalance, setLoadingScrtBalance] = useState(false);
  const [sefiBalance, setSefiBalance] = useState<string | null>(null);
  const [loadingSefiBalance, setLoadingSefiBalance] = useState(false);

  async function getScrtBalance(client: SigningCosmWasmClient, address: string) {
    setLoadingScrtBalance(true);
    const res: Account | undefined = await client.getAccount(address);
    if (res) {
      const scrtBalance = res.balance.find(balance => balance.denom === 'uscrt');
      scrtBalance && setScrtBalance(scrtBalance.amount);
    }
    setLoadingScrtBalance(false);
  }

  async function getSefiBalance(client: SigningCosmWasmClient, address: string) {
    setLoadingSefiBalance(true);
    try {
      const response = await window.keplr.getSecret20ViewingKey(globalThis.config.CHAIN_ID || process.env.REACT_APP_CHAIN_ID, globalThis.config.SEFI_CONTRACT_ADDRESS);
      const balanceResponse = await client.queryContractSmart(globalThis.config.SEFI_CONTRACT_ADDRESS, {
        balance: {
          address: address,
          key: response
        }
      });
      setSefiBalance(balanceResponse.balance.amount);
    } catch (e) {
      console.log(e);
    }
    setLoadingSefiBalance(false);
  }

  return { scrtBalance, loadingScrtBalance, getScrtBalance, sefiBalance, loadingSefiBalance, getSefiBalance };
}
