import { bech32, coinConvert } from '@stakeordie/griptape.js'
import { useContext, useEffect, useState } from 'react'
import { KeplrToolsContext } from '../context/KeplrToolsContext'
import globalStores from '../stores/globalStores'
import Loading from './Loading'
import MyOrdersModal from './MyOrdersModal'

export default () => {
    const keplrTools = useContext(KeplrToolsContext)
    const { loadingScrtBalance, loadingSefiBalance, getScrtBalance, scrtBalance, sefiBalance, getSefiBalance } = globalStores.walletInfoStore;
    const { createAuctionLoading, createAuction } = globalStores.factoryStore;
    const { setAuctionToRevealStatus, changeToRevealLoading, revealLosers, revealLosersLoading, auctionSelected, feedAuctionsInfo } = globalStores.auctionStore
    const [myOrdersModal, setMyOrdersModal] = useState(false);

    useEffect(() => {
        if (keplrTools) {
            getScrtBalance(keplrTools.client, keplrTools.accountAddress);
            getSefiBalance(keplrTools.client, keplrTools.accountAddress);
        }
    }, [keplrTools])

    const sefiBalanceRender = () => {
        if (loadingSefiBalance) return <Loading variantBlack={false} />
        if (!sefiBalance) return (
            <div onClick={async () => {
                if (keplrTools) {
                    await window.keplr.suggestToken(
                        globalThis.config.CHAIN_ID || process.env.REACT_APP_CHAIN_ID,
                        globalThis.config.SEFI_CONTRACT_ADDRESS
                    );
                    const sleep = (ms: number) => new Promise((accept) => setTimeout(accept, ms));
                    await sleep(1000);
                    getSefiBalance(keplrTools.client, keplrTools.accountAddress)
                }
            }}>
                <i className="fas fa-key"></i> SEFI
            </div>
        )
        return "" + coinConvert(sefiBalance ? sefiBalance : "", 6, 'human', 0) + " SEFI"
    }

    if (keplrTools) {
        return (
            <>
                <div className="flex flex-col">

                    <div className="hidden md:flex flex-col self-start sticky border-2 border-color-3 border-r rounded-2xl px-6 py-4 shadow-2xl bg-color-2">
                        <div className=" font-circular-std-book text-base text-color-13 mt-1 mb-4">
                            {bech32(keplrTools.accountAddress, 16)}
                        </div>
                        <div className="font-circular-std-book text-base text-color-8 my-1">
                            {
                                loadingScrtBalance ?
                                    <Loading variantBlack={false} /> :
                                    "" + coinConvert(scrtBalance ? scrtBalance : "", 6, 'human', 0) + " SCRT"
                            }
                        </div>
                        <div className="font-circular-std-book text-base text-color-8 my-1">
                            {sefiBalanceRender()}
                        </div>
                    </div>

                    <div className="flex flex-row justify-evenly text-color-13 mt-8">
                        <div onClick={() => window.open("https://twitter.com/reveal_network", "_blank")} className=' cursor-pointer'>
                            <i className="fab fa-twitter fa-2x"></i>
                        </div>
                        <div onClick={() => window.open("https://t.me/RevealedNetwork", "_blank")} className=' cursor-pointer'>
                            <i className="fab fa-telegram fa-2x"></i>
                        </div>
                        <div onClick={() => window.open("https://discord.gg/3gxfTVpz", "_blank")} className=' cursor-pointer'>
                            <i className="fab fa-discord fa-2x"></i>
                        </div>
                        <div onClick={() => window.open("https://docs.google.com/document/d/1NwWGalkWZZ3hvxD5XiXB-QF-AhyQq_LdJb_Fj0D5WsA", "_blank")} className=' cursor-pointer'>
                            <i className="fas fa-book fa-2x"></i>
                        </div>
                    </div>

                </div>
                <div className="flex flex-row">

                </div>
                <MyOrdersModal
                    myOrdersModal={myOrdersModal}
                    setMyOrdersModal={setMyOrdersModal}
                />
            </>
        )
    } else {
        return (
            <div className="flex flex-row justify-evenly text-color-13 mt-8">
                <div onClick={() => window.open("https://twitter.com/reveal_network", "_blank")} className=' cursor-pointer mx-4'>
                    <i className="fab fa-twitter fa-2x"></i>
                </div>
                <div onClick={() => window.open("https://t.me/RevealedNetwork", "_blank")} className=' cursor-pointer mx-4'>
                    <i className="fab fa-telegram fa-2x"></i>
                </div>
                <div onClick={() => window.open("https://discord.gg/3gxfTVpz", "_blank")} className=' cursor-pointer mx-4'>
                    <i className="fab fa-discord fa-2x"></i>
                </div>
                <div onClick={() => window.open("https://docs.google.com/document/d/1NwWGalkWZZ3hvxD5XiXB-QF-AhyQq_LdJb_Fj0D5WsA", "_blank")} className=' cursor-pointer mx-4'>
                    <i className="fas fa-book fa-2x"></i>
                </div>
            </div>
        )
    }
}