import React, { useEffect, useState } from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { AuctionInfo, Reveal } from '../stores/auctionStore';
import globalStores from '../stores/globalStores';

export default () => {
    const { auctionSelected, feedAuctionsInfo } = globalStores.auctionStore;

    if (!feedAuctionsInfo || !auctionSelected) return null
    return (
        <React.Fragment>

            {
                <div className="flex flex-col w-100 my-5">
                    {
                        feedAuctionsInfo[auctionSelected[0]].auction_state.mint_status && <TimelineElementMintPhase auctionInfo={feedAuctionsInfo[auctionSelected[0]]} />
                    }
                    {
                        !feedAuctionsInfo[auctionSelected[0]].auction_state.mint_status &&
                        <div className="flex flex-row w-full">
                            <div className={
                                `
                                ${feedAuctionsInfo[auctionSelected[0]].durations.next_reveal === 0 ? "timeline-active-1 " : "timeline-inactive "}
                                ${feedAuctionsInfo[auctionSelected[0]].durations.next_reveal && feedAuctionsInfo[auctionSelected[0]].durations.next_reveal! > 0 && "timeline-active-2 "}
                                ${feedAuctionsInfo[auctionSelected[0]].auction_state.winner_id && "timeline-active-2 "}
                                w-6/12 border-l-4 p-4 justify-start relative
                                `
                            }>
                                <div className="flex flex-col w-full">
                                    <span
                                        className={`
                                 ${feedAuctionsInfo[auctionSelected[0]].durations.next_reveal === 0 ? "text-color-13" : " text-color-5"}
                                  text-lg  font-circular-std-bold
                                `}
                                    >
                                        {
                                            feedAuctionsInfo[auctionSelected[0]].durations.mint_end && new Date(feedAuctionsInfo[auctionSelected[0]].durations.mint_end! * 1000).toLocaleString()
                                        }
                                    </span>
                                    <div className={`
                                        full-circle bg-color-13
                                    `}></div>
                                    <span
                                        className={`
                                ${feedAuctionsInfo[auctionSelected[0]].durations.next_reveal === 0 ? "text-color-8" : " text-color-5"}
                                 text-sm text-upper  font-circular-std-bold
                               `}
                                    >
                                        Pre-sale ended
                                    </span>
                                </div>

                            </div>
                            <div className="flex w-6/12 justify-end items-center">
                                <div className="flex flex-col justify-end bg-color-2 rounded-2xl text-right p-2 max-w-max max-h-max">
                                    <div className="font-circular-std-bold text-color-13 text-lg"> {feedAuctionsInfo[auctionSelected[0]].auction_state.minted_count} </div>
                                    <div className="font-circular-std-bold text-upper text-color-8 text-sm">Total tickets sold</div>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        feedAuctionsInfo[auctionSelected[0]].durations.reveals.map((reveal, i) =>
                            <TimelineElementReveal
                                reveal={reveal}
                                auctionInfo={feedAuctionsInfo[auctionSelected[0]]}
                                i={i}
                            />
                        )
                    }
                </div>
                /*
                 <Row style={{ marginTop: "50px" }}>
                <Col>
                    {
                        //@ts-ignore
                        <VerticalTimeline layout="1-column" lineColor="#2D353E">
                            {
                                auctionInfo.auction_state.mint_status && <TimelineElementMintPhase auctionInfo={auctionInfo} />
                            }
                            {
                                !auctionInfo.auction_state.mint_status &&
                                <VerticalTimelineElement
                                    iconStyle={{ background: auctionInfo.durations.next_reveal === 0 ? "white" : '#2D353E', boxShadow: 'none', height: "25px", width: "25px", top: "10px" }}
                                    style={{ right: "-7px", marginTop: "20px", marginBottom: "20px", height: "70px" }}
                                    contentArrowStyle={{ display: "none" }}
                                    contentStyle={{ backgroundColor: "transparent", boxShadow: "none", padding: "0px" }}
                                    dateClassName="d-none"
                                >
                                    <Row>
                                        <Col xs={4} style={{}}>
                                            <span className="general-text-white-16px" style={{ color: auctionInfo.durations.next_reveal === 0 ? "white" : "#5C6269" }}>
                                                {
                                                    auctionInfo.durations.mint_end && new Date(auctionInfo.durations.mint_end * 1000).toLocaleString()
                                                }
                                            </span>
                                            <br />
                                            <span className="general-text-12px" style={{ color: auctionInfo.durations.next_reveal === 0 ? "#A2A5A9" : "#5C6269" }}>
                                                Pre-sale ended
                                            </span>
                                        </Col>
                                        <Col style={{ display: "flex", justifyContent: "right" }}>
                                            <div style={{ background: "#1D262F", borderRadius: "16px", textAlign: "center", padding: "10px", alignSelf: "center", height: "70px" }}>
                                                <span style={{ fontWeight: "bold", fontSize: "16px", lineHeight: "16px" }}>
                                                    {auctionInfo.auction_state.minted_count}
                                                </span>
                                                <br />
                                                <span className="general-text-12px">
                                                    Total tickets sold
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>
                                </VerticalTimelineElement>
                            }
                            {
                                auctionInfo.durations.reveals.map((reveal, i) =>
                                    <TimelineElementReveal
                                        reveal={reveal}
                                        auctionInfo={auctionInfo}
                                        i={i}
                                    />
                                )
                            }
                        </VerticalTimeline>
                    }
                </Col>
            </Row>
                */
            }

        </React.Fragment>

    )
}

const TimelineElementMintPhase = ({
    auctionInfo
}: {
    auctionInfo: AuctionInfo,
}) => {


    return (
        <div className="flex flex-row w-full">
            <div className={
                `
                timeline-active-1 w-8/12 border-l-4 p-4 justify-start relative
                `
            }>
                <div className={`
                    full-circle  bg-color-13 top-18
                `}></div>
                <div className="flex flex-col bg-color-2 rounded-2xl border-2 border-color-3 p-4 max-w-max">
                    <Countdown auctionInfo={auctionInfo} />
                    <span className="text-upper font-circular-std-bold text-sm text-color-8 mt-4">
                        Pre-sale ends
                    </span>
                </div>
            </div>
            <div className="flex w-4/12 justify-end items-center">
                <div className="flex flex-col justify-end bg-color-2 rounded-2xl text-right p-2 max-w-max max-h-max">
                    <div className="font-circular-std-bold text-color-13 text-lg"> {auctionInfo.auction_state.minted_count} </div>
                    <div className="font-circular-std-bold text-upper text-color-8 text-sm">Total tickets sold</div>
                </div>
            </div>
        </div>
    )
}

export const Countdown = ({
    auctionInfo
}: {
    auctionInfo: AuctionInfo,
}) => {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(auctionInfo.durations.mint_start + auctionInfo.durations.mint_expected_duration));

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft(auctionInfo.durations.mint_start + auctionInfo.durations.mint_expected_duration));
        }, 1000);
    });
    return (
        <div className="flex flex-row w-full place-content-center">
            <div className="flex flex-col w-1/8 justify-center item-center text-center ">
                <div className=" font-circular-std-bold text-color-13 text-3xl mb-1">{timeLeft.days}</div>
                <div className=" font-circular-std-bold text-color-6 text-upper text-xs">Days</div>
            </div>
            <div className="flex flex-col w-1/8 justify-center items-center mx-5 p-2 font-circular-std-bold text-color-13 text-3xl">
                <div>:</div>
            </div>
            <div className="flex flex-col w-1/8 justify-center item-center text-center ">
                <div className=" font-circular-std-bold text-color-13 text-3xl mb-1">{timeLeft.hours}</div>
                <div className=" font-circular-std-bold text-color-6 text-upper text-xs">Hours</div>
            </div>
            <div className="flex flex-col w-1/8 justify-center items-center mx-5 p-2 font-circular-std-bold text-color-13 text-3xl">
                <div>:</div>
            </div>
            <div className="flex flex-col w-1/8 justify-center item-center text-center ">
                <div className=" font-circular-std-bold text-color-13 text-3xl mb-1">{timeLeft.minutes}</div>
                <div className=" font-circular-std-bold text-color-6 text-upper text-xs">Minutes</div>
            </div>
            <div className="flex flex-col w-1/8 justify-center items-center mx-5 p-2 font-circular-std-bold text-color-13 text-3xl">
                <div>:</div>
            </div><div className="flex flex-col w-1/8 justify-center item-center text-center ">
                <div className=" font-circular-std-bold text-color-13 text-3xl mb-1">{timeLeft.seconds}</div>
                <div className=" font-circular-std-bold text-color-6 text-upper text-xs">Seconds</div>
            </div>
        </div>
    )
}

const calculateTimeLeft = (endTimestamp: number) => {
    let date = new Date();
    let expectedFinalDate = new Date(endTimestamp * 1000);
    let difference = +expectedFinalDate - +date;
    let timeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    };

    if (difference > 0) {
        timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60)
        };
    }

    return timeLeft;

}


const TimelineElementReveal = ({
    reveal,
    auctionInfo,
    i
}: {
    reveal: Reveal,
    auctionInfo: AuctionInfo,
    i: number
}) => {

    const calcDate = () => {
        if (reveal.reveal_excution_time) return reveal.reveal_excution_time * 1000
        const mintEndDate = auctionInfo.durations.mint_end ? auctionInfo.durations.mint_end : (auctionInfo.durations.mint_start + auctionInfo.durations.mint_expected_duration)
        let result = mintEndDate;
        for (var j = 0; j < auctionInfo.durations.reveals.length; j++) {
            // if (auctionInfo.durations.reveals[j].reveal_excution_time) result = auctionInfo.durations.reveals[j].reveal_excution_time!;
            // else result = result + auctionInfo.durations.reveals[j].reveal_expected_duration;
            result = result + auctionInfo.durations.reveals[j].reveal_expected_duration;
            if (j >= i) break;
        }

        return result * 1000
    }

    const calcActiveIcon = () => {
        if ((auctionInfo.auction_state.winner_status || auctionInfo.auction_state.finished_status) && auctionInfo.durations.next_reveal === null && auctionInfo.durations.reveals.length === i + 1)
            return true

        if (auctionInfo.durations.next_reveal && auctionInfo.durations.next_reveal !== 0 && auctionInfo.durations.next_reveal - 1 === i)
            return true

        return false
    }

    return (
        <div className="flex flex-row w-full">
            <div className={
                `
                ${calcActiveIcon() ? "timeline-active-1" : "timeline-inactive"}
                ${auctionInfo.durations.next_reveal && auctionInfo.durations.next_reveal > i && "timeline-active-2 "}
                ${auctionInfo.auction_state.winner_id && "timeline-active-2 "}
                w-6/12 border-l-4 p-4 justify-start relative
                `
            }>
                <div className="flex flex-col w-full">
                    <span
                        className={`
                                 ${calcActiveIcon() ? "text-color-13" : " text-color-5"}
                                  text-lg  font-circular-std-bold
                                `}
                    >
                        {
                            new Date(calcDate()).toLocaleString()
                        }
                    </span>
                    <div className={`
                        ${(calcActiveIcon() || (auctionInfo.durations.next_reveal && auctionInfo.durations.next_reveal > i) || (auctionInfo.auction_state.winner_id)) ? "bg-color-13" : "bg-color-3"}
                        full-circle 
                    `}></div>
                    <span
                        className={`
                                ${calcActiveIcon() ? "text-color-8" : " text-color-5"}
                                 text-sm text-upper  font-circular-std-bold
                               `}
                    >
                        {
                            calcActiveIcon() &&
                                (auctionInfo.auction_state.winner_status || auctionInfo.auction_state.finished_status) && auctionInfo.durations.next_reveal === null && auctionInfo.durations.reveals.length === i + 1 ?
                                "Finished" :
                                "Reveal " + (i + 1)
                        }
                    </span>
                </div>
            </div>
            <div className="flex w-6/12 justify-end items-center">
                {
                    calcActiveIcon() &&
                    auctionInfo.in_play_tokens.length > 0 &&
                    <div className="flex flex-col justify-end bg-color-2 rounded-2xl text-right p-2 max-w-max max-h-max">
                        <div className="font-circular-std-bold text-color-13 text-lg">
                            {
                                auctionInfo.auction_state.minted_count - auctionInfo.auction_state.reveal_count
                            }
                        </div>
                        <div className="font-circular-std-bold text-upper text-color-8 text-sm">Tickets Left</div>
                    </div>
                }
            </div>
        </div>
    )
}