import { useState } from 'react';
import { SigningCosmWasmClient } from 'secretjs';
import erroTxHandler from '../utils/erroTxHandler';
import { TxHandler } from '../utils/interfaces';
import permitDetails from '../utils/permitDetails';
import globalStores from './globalStores';
export interface MyNFTsStore {
    clearState(): null,
    myNFTs: (string[] | null)[],
    getMyNFTs(client: SigningCosmWasmClient, myAddress: string, nftsContractAddress: string, auctionIndex: number): Promise<void>,
    getMyNFTsLoading: boolean;
    claimRewardsLoading: boolean,
    claimRewards(client: SigningCosmWasmClient, id: string): Promise<TxHandler>,
}

export function useMyNFTsStore() {
    const [myNFTs, setMyNFTs] = useState<(string[] | null)[]>([null, null]);
    const [getMyNFTsLoading, setGetMyNFTsLoading] = useState(false);

    const clearState = () => {
        setMyNFTs([null, null])
        return null
    }

    const getMyNFTs = async (client: SigningCosmWasmClient, myAddress: string, nftsContractAddress: string, auctionIndex: number) => {
        setGetMyNFTsLoading(true)
        /*
        //using permits for auth
        let permit = JSON.parse(
            localStorage.getItem(`query_permit_${myAddress + "_" + nftsContractAddress}`) as string
        );
        if (!permit) permit = await permitDetails(myAddress, nftsContractAddress)

        const res = await client.queryContractSmart(nftsContractAddress, {
            with_permit: {
                query: { tokens: { owner: myAddress, limit: 10000 } },
                permit
            }
        })
        */

        //using vk for auth
        const { getUserViewingKey } = globalStores.factoryStore;
        const res = await client.queryContractSmart(nftsContractAddress, {
            tokens: { owner: myAddress, limit: 10000, viewing_key: getUserViewingKey(myAddress) }
        })

        let newMyNFTs = [...myNFTs]
        newMyNFTs[auctionIndex] = res.token_list.tokens
        setMyNFTs(newMyNFTs)
        setGetMyNFTsLoading(false)
    }

    const [claimRewardsLoading, setClaimRewardsLoading] = useState<boolean>(false);
    const claimRewards = async (client: SigningCosmWasmClient, id: string) => {
        setClaimRewardsLoading(true)
        try {
            const { auctionSelected, feedAuctionsInfo, getFeedAuctions } = globalStores.auctionStore;
            const { auctions } = globalStores.factoryStore;
            if (!feedAuctionsInfo || !auctionSelected || !auctions) throw new Error();
            const fee = {
                amount: [{ amount: "300000", denom: "uscrt" }],
                gas: "130000",
            };
            await client.execute(feedAuctionsInfo[auctionSelected[0]].configs.nfts_contract.contract!.address, {
                batch_send_nft: {
                    sends: [{
                        contract: auctionSelected[1],
                        token_ids: [id],
                        msg: btoa(JSON.stringify({
                            claim_reward: {
                            }
                        }))
                    }]
                }
            }, undefined, undefined, fee);
            await getFeedAuctions(client, auctions)
        } catch (e: any) {
            setClaimRewardsLoading(false)
            console.log(e)
            return ({
                ok: false,
                error: erroTxHandler(e.message)
            })
        }
        setClaimRewardsLoading(false)
        return ({
            ok: true
        })
    }

    return {
        clearState,
        myNFTs,
        getMyNFTsLoading,
        getMyNFTs,
        claimRewards,
        claimRewardsLoading
    };
}