// This is a conditional require statement.  So if REACT_APP_NETWORK is 'secret_4' it will load secret_4.js
const networkConfig = require('./' + process.env.REACT_APP_NETWORK).default;

// Merge the networkConfig into Typescript's global scope
globalThis.config = { ...networkConfig };

// Necessary because create-react-app enforces isolatedModules
export { }

declare global {
    var config: {
        CHAIN_ID: string,
        REST: string,
        RPC: string,
        SEFI_CONTRACT_ADDRESS: string,
        FACTORY_CONTRACT_ADDRESS: string
    }
}