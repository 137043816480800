import { coinConvert } from "@stakeordie/griptape.js";
import React, { Dispatch, useContext, useEffect, useState } from "react";
import { KeplrToolsContext } from "../context/KeplrToolsContext";
import globalStores from "../stores/globalStores";
import Loading from "./Loading";

export default ({
    myOrdersModal,
    setMyOrdersModal
}: {
    myOrdersModal: boolean,
    setMyOrdersModal: Dispatch<boolean>
}) => {
    const keplrTools = useContext(KeplrToolsContext)
    const { auctionSelected, feedAuctionsInfo } = globalStores.auctionStore;
    const { addBuyOrderLoading, addBuyOrder, getUserOrdersState, userOrdersStateLoading, userOrdersState, cancelBuyOrder, cancelBuyOrderLoading } = globalStores.orderbookStore;
    const [ticketPrice, setTicketPrice] = useState("");
    const [ticketCount, setTicketCount] = useState("");

    useEffect(() => {
        if (myOrdersModal && keplrTools) {
            //getUserOrdersState(keplrTools.client, keplrTools.accountAddress)
        }
    }, [myOrdersModal, keplrTools])

    const totalSEFI = () => {
        if (!feedAuctionsInfo || !auctionSelected || ticketCount === "") return 0
        else return (parseFloat(ticketPrice) * 1000000) * parseInt(ticketCount)
    }

    if (!feedAuctionsInfo || !auctionSelected || !keplrTools) return null
    if (myOrdersModal)
        return (
            <div className="fixed z-10 inset-0 overflow-y-auto">
                {
                    /*
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity">
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                        <div className="border-color-3 inline-block align-bottom bg-color-2 rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full py-6 px-6">
                            {
                                <>
                                    <div className="flex flex-row w-full">
                                        <div className="flex flex-col font-circular-std-black text-color-13 text-2xl">
                                            My Orders
                                        </div>
                                        <div className="flex flex-col justify-center items-center bg-color-3 text-color-13  ml-auto w-8 h-8 cursor-pointer rounded-3xl p-4 text-base" onClick={() => setMyOrdersModal(false)}>
                                            X
                                        </div>
                                    </div>
                                    {
                                        userOrdersStateLoading && <Loading variantBlack={false} />
                                    }
                                    {
                                        !userOrdersStateLoading && userOrdersState && userOrdersState.buy_order_not_revealed_active &&
                                        <button className=" bg-red-400 p-4" onClick={() => cancelBuyOrder(keplrTools.client, userOrdersState.buy_order_not_revealed_active!.price, 0, userOrdersState.buy_order_not_revealed_active!.revealed!, userOrdersState.buy_order_not_revealed_active!.id)}> del </button>
                                    }
                                    {
                                        cancelBuyOrderLoading && <Loading variantBlack={false} />
                                    }
                                    {
                                        !userOrdersStateLoading &&
                                        userOrdersState && !userOrdersState.buy_order_not_revealed_active &&
                                        <>
                                            <div className="flex flex-row w-full pt-8 pb-4">
                                                <div className="flex flex-col font-circular-std-book text-color-13 text-base justify-center">
                                                    How many tickets would you like to buy?
                                                </div>
                                                <div className="flex flex-col justify-center items-center ml-auto">
                                                    <input
                                                        value={ticketCount}
                                                        className={
                                                            `
                                        ${ticketCount === "" ? "text-color-4" : "text-color-13"}
                                         w-32
                                         p-4 bg-color-3 rounded-lg font-circular-std-book text-4xl text-center`
                                                        }
                                                        placeholder="0000"
                                                        onChange={(e) => {
                                                            const reg = new RegExp('^[0-9]+$');
                                                            let value = e.target.value
                                                            if (!reg.test(value)) value = ""
                                                            if (parseInt(value) > 9999) value = "9999"
                                                            setTicketCount(value)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex flex-row w-full pt-8 pb-4">
                                                <div className="flex flex-col font-circular-std-book text-color-13 text-base justify-center w-1/2">
                                                    At what price per ticket?
                                                </div>
                                                <div className="flex flex-col justify-center items-center w-1/2">
                                                    <input
                                                        value={ticketPrice}
                                                        className={
                                                            `
                                        ${ticketPrice === "" ? "text-color-4" : "text-color-13"}
                                         p-4 bg-color-3 rounded-lg font-circular-std-book text-4xl text-center w-64`
                                                        }
                                                        placeholder={"0.00"}
                                                        onChange={(e) => {
                                                            let value = e.target.value
                                                            const reg = new RegExp('([0-9]*[.])?[0-9]+');
                                                            if (!reg.test(value)) value = ""
                                                            //value = "" + parseFloat(value).toFixed(2)
                                                            //value = "" + Math.round(parseFloat(value) * 100) / 100
                                                            setTicketPrice(value)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="border-1 border-color-4 my-1" />
                                            <div className="flex flex-row w-full py-4">
                                                <div className="flex flex-col justify-center text-color-8 font-circular-std-bold text-xs text-upper mr-4">
                                                    Total
                                                </div>
                                                <div className="flex flex-col justify-center font-circular-std-bold text-xl text-color-13">
                                                    {
                                                        ticketCount !== "" ?
                                                            (coinConvert("" + totalSEFI(), 6, 'human', 2) + " SEFI") :
                                                            "0.00 SEFI"
                                                    }
                                                </div>
                                            </div>
                                            <div className="border-1 border-color-4 my-1" />
                                            <div className="flex flex-row w-full mt-5">
                                                <button
                                                    className={
                                                        `
                                        ${!addBuyOrderLoading && " disabled:opacity-50 hover:bg-color-13 "}
                                        w-full bg-color-11 rounded-3xl shadow-2xl p-4 font-circular-std-bold text-color-1 text-sm 
                                        `
                                                    }
                                                    disabled={ticketCount === "" || addBuyOrderLoading || ticketPrice === ""}
                                                    onClick={async () => {
                                                        const res = await addBuyOrder(keplrTools.client, parseFloat(ticketPrice) * 1000000, parseInt(ticketCount))
                                                        setMyOrdersModal(false)
                                                    }}>
                                                    {
                                                        addBuyOrderLoading ?
                                                            <Loading variantBlack={true} />
                                                            :
                                                            ticketCount !== "" ?
                                                                "Pay " + (coinConvert("" + totalSEFI(), 6, 'human', 2) + " SEFI") :
                                                                "Pay 0.00 SEFI"
                                                    }
                                                </button>
                                            </div>
                                        </>
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
                    */
                }

            </div >)
    else return null
}