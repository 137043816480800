import { coinConvert } from "@stakeordie/griptape.js"
import React, { Dispatch, useContext, useEffect, useState } from "react"
import ReactTooltip from "react-tooltip"
import { KeplrToolsContext } from "../context/KeplrToolsContext"
import globalStores from "../stores/globalStores"
import BuyTicketsModal from "./BuyTicketsModal"
import BuyTicketsOrderBookModal from "./BuyTicketsOrderBookModal"
import Loading from "./Loading"
import MainAreaTimeline from "./MainAreaTimeline"
import MyNFTsRow from "./MyNFTsRow"

export default ({
    setActiveMenu
}: {
    setActiveMenu: Dispatch<string>
}) => {
    const keplrTools = useContext(KeplrToolsContext)
    const { auctions, auctionsLoading } = globalStores.factoryStore;
    const { setAuction, auctionSelected, feedAuctionsInfo } = globalStores.auctionStore;
    const { myNFTs, getMyNFTsLoading } = globalStores.myNFTsStore;
    const { userOrdersState, userOrdersStateLoading } = globalStores.orderbookStore;

    const [buyTicketsModal, setBuyTicketsModal] = useState(false);
    const [buyTicketsOrderBookModal, setBuyTicketsOrderBookModal] = useState(false);

    const disableBuyButton = () => {
        if (!userOrdersState) return true
        if (!feedAuctionsInfo || !auctionSelected) return true
        if (!feedAuctionsInfo[auctionSelected[0]].auction_state.mint_status && !feedAuctionsInfo[auctionSelected[0]].auction_state.reveal_status) return true
        if (feedAuctionsInfo[auctionSelected[0]].auction_state.reveal_status) {
            if (!userOrdersState[auctionSelected[0]]) return true
            if (userOrdersState[auctionSelected[0]] && userOrdersState[auctionSelected[0]]!.buy_order_not_revealed_active) return true
        }
        return false
    }

    if (!keplrTools || !feedAuctionsInfo || !auctionSelected) return null
    return (
        <>
            <div className="text-color-13 text-base font-circular-std-bold cursor-pointer" onClick={() => {
                setActiveMenu("Feed")
                setAuction(keplrTools.client, keplrTools.accountAddress, null)
            }}>
                <i className="fas fa-arrow-left mr-2"></i> Back to Feed
            </div>
            <div className="flex flex-row w-full my-9">
                {feedAuctionsInfo[auctionSelected[0]] &&
                    <>
                        <div className="flex flex-col ">
                            <div className="text-color-13 text-6xl font-circular-std-bold">
                                {
                                    "" + coinConvert(feedAuctionsInfo[auctionSelected[0]].auction_state.mint_pot, 6, 'human', 0) + " SEFI"
                                }
                            </div>
                            <div className="text-color-8 text-xs font-circular-std-bold text-upper mt-2">
                                Lottery Jackpot
                            </div>
                        </div>
                        <div className="flex flex-col justify-center items-center ml-auto">
                            {
                                <div className='ml-auto'>
                                    <a data-tip="You can only have 1 active buy order at a time!">
                                        <button
                                            className="bg-color-11 shadow-2xl rounded-3xl p-4 font-circular-std-bold text-sm text-color-1 hover:bg-color-13 w-48 disabled:opacity-50"
                                            disabled={disableBuyButton()}
                                            onClick={() => {
                                                if (feedAuctionsInfo[auctionSelected[0]].auction_state.mint_status) setBuyTicketsModal(true)
                                                else if (feedAuctionsInfo[auctionSelected[0]].auction_state.reveal_status) setBuyTicketsOrderBookModal(true)
                                            }}
                                        >
                                            Buy Tickets
                                        </button>
                                    </a>
                                    {
                                        auctionSelected &&
                                        feedAuctionsInfo[auctionSelected[0]].auction_state.reveal_status &&
                                        userOrdersState[auctionSelected[0]] && userOrdersState[auctionSelected[0]]!.buy_order_not_revealed_active &&
                                        <ReactTooltip
                                            place="top"
                                            backgroundColor='#2D353E'
                                            textColor='#FFFFFF'
                                            className='custom-color'
                                            effect='solid'
                                            class="font-circular-std-bold"
                                            offset={{ top: 10 }}
                                        />
                                    }

                                </div>
                            }
                            {
                                feedAuctionsInfo[auctionSelected[0]].auction_state.mint_status &&
                                <div className="text-color-8 text-sm font-circular-std-book mt-2">
                                    {
                                        coinConvert(feedAuctionsInfo[auctionSelected[0]].configs.nft_price, 6, 'human', 0) + " SEFI/Each"
                                    }
                                </div>
                            }
                        </div>
                    </>
                }
            </div>
            {
                getMyNFTsLoading && <div className="flex flex-row w-full my-2 justify-center "><Loading variantBlack={false} /></div>
            }
            {
                !getMyNFTsLoading && myNFTs[auctionSelected[0]] && myNFTs[auctionSelected[0]]!.length > 0 &&
                <MyNFTsRow />
            }
            <BuyTicketsModal
                buyTicketsModal={buyTicketsModal}
                setBuyTicketsModal={setBuyTicketsModal}
            />
            <BuyTicketsOrderBookModal
                buyTicketsOrderBookModal={buyTicketsOrderBookModal}
                setBuyTicketsOrderBookModal={setBuyTicketsOrderBookModal}
            />
            <div className="border-1 border-color-3 my-4" />
        </>

        /*
        <Row style={{ padding: "0px", margin: "0px" }}>
            <Col>
                <span className="jackpot-value">
                    {
                        auctionInfoLoading ?
                            <Loading /> :
                            "" + coinConvert(auctionInfo.auction_state.mint_pot, 6, 'human', 2) + " SEFI"
                    }
                </span>
                <span className="jackpot-value-label">Auction Jackpot</span>
            </Col>
            <Col style={{
                //@ts-ignore
                textAlign: "-webkit-right"
            }}>
                <button className="buy-tickets-button" disabled={!auctionInfo.auction_state.mint_status} onClick={() => setBuyTicketsModal(true)}>Buy Tickets</button>
                <span className="ticket-cost-label">
                    {
                        auctionInfoLoading ?
                            <Loading /> :
                            coinConvert(auctionInfo.configs.nft_price, 6, 'human', 2) + " SEFI/Each"
                    }
                </span>
            </Col>
            <BuyTicketsModal
                buyTicketsModal={buyTicketsModal}
                setBuyTicketsModal={setBuyTicketsModal}
            />
        </Row>
    */)
}